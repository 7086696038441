/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { documentAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import { validateEmail } from "../../Common/utils/extraFunctions";

const ManualEntry = ({
  handleCancelPropertySearch,
  setFieldIsEmpty,
  fieldEmpty,
  form,
  listingForm,
  setListingForm,
  redirectFromClient
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTab = useMediaQuery({ maxWidth: 950 });

  const { addPropertyLoading } = useSelector(
    (state) => state.documentSection.PropertySearchReducer
  );
  const selectOptions = [
    { value: "East", label: "East" },
    { value: "West", label: "West" },
    { value: "North", label: "North" },
    { value: "South", label: "South" },
    { value: "Northeast", label: "North East" },
    { value: "Northwest", label: "North West" },
    { value: "Southeast", label: "South East" },
    { value: "Southwest", label: "South West" },
  ];

  function listingChange(value, key) {
    const fieldEmptyUpdated = listingForm;
    fieldEmptyUpdated[key] = value;
    setListingForm({ ...fieldEmptyUpdated });
  }
  // console.log("listingForm", listingForm);
  const handleListingForm = () => {
    let urlData = {
      ...((listingForm.email && validateEmail(listingForm.email)) ? { email: listingForm.email } : {}),
      ...(listingForm.firstName ? { firstName: listingForm.firstName } : {}),
      ...(listingForm.middleName ? { middleName: listingForm.middleName } : {}),
      ...(listingForm.lastName ? { lastName: listingForm.lastName } : {}),
      ...(listingForm.StreetNumber ? { StreetNumber: listingForm.StreetNumber } : {}),
      ...(listingForm.StreetName ? { StreetName: listingForm.StreetName } : {}),
      ...(listingForm.DirPrefix ? { DirPrefix: listingForm.DirPrefix } : {}),
      ...(listingForm.StreetSuffix ? { StreetSuffix: listingForm.StreetSuffix } : {}),
      ...(listingForm.DirSuffix ? { DirSuffix: listingForm.DirSuffix } : {}),
      ...(listingForm.UnitLabel ? { UnitLabel: listingForm.UnitLabel } : {}),
      ...(listingForm.UnitNumber ? { UnitNumber: listingForm.UnitNumber } : {}),
      ...(listingForm.State ? { State: listingForm.State } : {}),
      ...(listingForm.City ? { City: listingForm.City } : {}),
      ...(listingForm.ZipCode ? { ZipCode: listingForm.ZipCode } : {}),
    };
    dispatch(documentAction.addListingProperty({ data: urlData, setFieldIsEmpty, fieldEmpty, redirectFromClient }));
    handleCancelPropertySearch();
    form.resetFields();
    setListingForm({
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      StreetNumber: null,
      StreetName: "",
      UnitLabel: "Unit",
      UnitNumber: "",
      DirPrefix: "",
      DirSuffix: "",
      StreetSuffix: "",
      State: "CO",
      City: "",
      ZipCode: null,
    });
  };

  return (
    <div
      style={{
        maxHeight: isMobile ? "" : "550px",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      {addPropertyLoading ? (
        <Loading />
      ) : (
        <div style={{ padding: "5px" }}>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="email" label="Listing Agent Email" className="lbl" 
                  rules={[
                    { required: true, message: "Please enter the Listing Agent Email." },
                    {
                      validator: (_, value) => {
                        if (!value || validateEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please enter a valid email address."));
                      },
                    },
                    ]}
                >
                  <Input
                    onChange={(e) => {
                      listingChange(e.target.value, "email");
                    }}
                    placeholder="Enter Listing Agent Email"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item className="lbl1" name="firstName" label="Listing Agent First Name" rules={[{ required: true, message: "Please enter the Listing Agent First Name." }]}>
                  <Input
                    onChange={(e) => {
                      listingChange(e.target.value, "firstName");
                    }}
                    placeholder="Enter Listing Agent First Name"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="middleName" label="Listing Agent Middle Name" className="lbl1">
                  <Input
                    onChange={(e) => {
                      listingChange(e.target.value, "middleName");
                    }}
                    placeholder="Enter Listing Agent Middle Name"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item className="lbl1" name="lastName" label="Listing Agent Last Name" rules={[{ required: true, message: "Please enter the Listing Agent Last Name." }]}>
                  <Input
                    onChange={(e) => {
                      listingChange(e.target.value, "lastName");
                    }}
                    placeholder="Enter Listing Agent Last Name"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="StreetNumber" label="Street Number" className="lbl" rules={[{ required: true, message: "Please enter the Street Number." }]}>
                  <Input
                    type="number"
                    onChange={(e) => {
                      listingChange(e.target.value, "StreetNumber");
                    }}
                    placeholder="Enter Street Number"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="DirPrefix" label="Direction Prefix" className="lbl1">
                  <Select
                    placeholder="Select Direction Prefix"
                    options={selectOptions}
                    size="middle"
                    style={{ fontSize: "16px" }}
                    onChange={(e) => {
                      listingChange(e, "DirPrefix");
                    }}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="StreetName"
                  label="Street Name"
                  className="lbl"
                  rules={[{ required: true, message: "Please enter the Street Name." }]}
                  onChange={(e) => {
                    listingChange(e.target.value, "StreetName");
                  }}
                >
                  <Input placeholder="Enter Street Name" allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="StreetSuffix" label="Street Suffix" className="lbl1">
                  <Input
                    placeholder="Enter Street Suffix"
                    onChange={(e) => {
                      listingChange(e.target.value, "StreetSuffix");
                    }}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="DirSuffix" label="Direction Suffix" className="lbl1">
                  <Select
                    placeholder="Select Direction Suffix"
                    options={selectOptions}
                    size="middle"
                    style={{ fontSize: "16px" }}
                    onChange={(e) => {
                      listingChange(e, "DirSuffix");
                    }}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Form.Item className="lbl1" label="Unit Label" name="UnitLabel" style={{ flex: 1 }}>
                    <Input placeholder="Enter Unit, Apt, or Suite" allowClear     onChange={(e) => {
                      listingChange(e.target.value, "UnitLabel");
                    }} />
                  </Form.Item>
                  <Form.Item className="lbl1" label="Unit Number" name="UnitNumber" style={{ flex: 1 }}>
                    <Input placeholder="Enter Unit Number" allowClear      onChange={(e) => {
                      listingChange(e.target.value, "UnitNumber");
                    }} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item className="lbl1" name="City" label="City" rules={[{ required: true, message: "Please enter the City." }]}>
                  <Input
                    onChange={(e) => {
                      listingChange(e.target.value, "City");
                    }}
                    placeholder="Enter City"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item className="lbl1" name="State" label="State" rules={[{ required: true, message: "Please enter the State." }]}>
                  <Input
                    defaultValue="CO"
                    onChange={(e) => {
                      listingChange(e.target.value, "State");
                    }}
                    placeholder="Enter State"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  className="lbl1"
                  name="ZipCode"
                  label="Zip Code"
                  hasFeedback
                  rules={[
                    { required: true, message: "" },
                    { whitespace: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value?.length > 4 && value?.length < 7) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please enter a valid Zip Code."));
                      },
                    }),
                  ]}
                >
                  <Input
                    type="number"
                    maxLength={"6"}
                    allowClear
                    onChange={(e) => {
                      listingChange(e.target.value, "ZipCode");
                    }}
                    placeholder="Enter Zip Code"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item>
                  <Button
                    style={{
                      width: "100%",
                      minWidth: "130px",
                      marginTop: "20px",
                      marginBottom: "-20px",
                      marginLeft: isMobile ? "" : isTab ? "" : "50%",
                    }}
                    type="primary"
                    onClick={handleListingForm}
                    disabled={!listingForm.firstName || !listingForm.lastName || !listingForm.StreetNumber || !listingForm.StreetName || !listingForm.City || !listingForm.State || !listingForm.ZipCode || !validateEmail(listingForm.email)}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ManualEntry;
