import { combineBrokersFirmName, formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { getFullNamesFromArray } from "../../DocumentSection/utils/helper";
import axios from "axios";
const apiURL = process.env.REACT_APP_BASE_URL;

const moment = require("moment");
export const getBuildDataForNewClientdoc = ({ createdDocItem, parentClientdocItem, personDetails, getUserDetailsData, brokerLicenseId, docSecondItem, personDocs }) => {
  let Buyers = [];
  let Sellers = [];
  let BuyerAgents = [],
    SellerAgents = [];
  if (["ERTB", "CB_ERTB", "BDB", "FRASCONA_BDB"].includes(parentClientdocItem.contractType) || docSecondItem?.contractType === "LCAE") {
    Buyers.push({
      personId: personDetails?._id,
      fullName: personDetails?.fullName,
      isCorp: false,
      signers: [],
    });
    BuyerAgents.push({
      personId: getUserDetailsData?._id,
      fullName: getUserDetailsData?.fullName,
      email: getUserDetailsData?.email,
      firmName: getUserDetailsData?.brokerageData?.brokerageName,
      resureFirmId: getUserDetailsData?.brokerageData?.brokerageId?._id,
      firmLicense: getUserDetailsData?.brokerageData?.brokerageLicenseNumber,
      license: brokerLicenseId,
      address: getUserDetailsData?.brokerageData?.brokerageAddress || "",
      phoneNumber: formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber),
      faxNumber: formatPhoneNumber(getUserDetailsData?.brokerageData?.brokerageFaxNumber, "FAX") || "",
    });
  } else {
    Sellers.push({
      personId: personDetails?._id,
      fullName: personDetails?.fullName,
      isCorp: false,
      signers: [],
    });
    SellerAgents.push({
      personId: getUserDetailsData?._id,
      fullName: getUserDetailsData?.fullName,
      email: getUserDetailsData?.email,
      resureFirmId: getUserDetailsData?.brokerageData?.brokerageId?._id,
      firmName: getUserDetailsData?.brokerageData?.brokerageName,
      firmLicense: getUserDetailsData?.brokerageData?.brokerageLicenseNumber,
      license: brokerLicenseId,
      address: getUserDetailsData?.brokerageData?.brokerageAddress || "",
      phoneNumber: formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber),
      faxNumber: formatPhoneNumber(getUserDetailsData?.brokerageData?.brokerageFaxNumber, "FAX") || "",
    });
  }
  const officeDetails = {
    officeLogo: getUserDetailsData?.brokerageData?.brokerageLogo || getUserDetailsData?.brokerageData?.brokerageId?.logo || "",
    name: getUserDetailsData?.brokerageData?.brokerageName?.trim() || "",
    address: getUserDetailsData?.brokerageData?.brokerageAddress || "",
    agentId: getUserDetailsData?._id,
  };
  let buildData = {
    urlData: {
      officeDetails,
      ...(Buyers.length ? { Buyers } : {}),
      ...(Sellers.length ? { Sellers } : {}),
      ...(BuyerAgents.length > 0 ? { BuyerAgents, BuyerBrokersList: getFullNamesFromArray(BuyerAgents), BuyerBrokersFirmList: combineBrokersFirmName(BuyerAgents) } : {}),
      ...(SellerAgents.length > 0 ? { SellerAgents, SellerBrokersList: getFullNamesFromArray(SellerAgents), SellerBrokersFirmList: combineBrokersFirmName(SellerAgents) } : {}),
      ...(docSecondItem?.contractType === "LCAE" ? { LcaeCheckedListingContract: "ERTBListingContract" } : docSecondItem?.contractType === "LCAES_ERTL" ? { LcaeCheckedListingContract: "ERTLListingContract" } : docSecondItem?.contractType === "LCAES_ETCT" ? { LcaeCheckedListingContract: "ExclusiveTenantListingContract" } : {}),
      ...(docSecondItem?.contractType === "LCAE" ? { LcaeInitatingParty: "LcaeInitatingPartyBuyer" } : docSecondItem?.contractType === "LCAES_ERTL" ? { LcaeInitatingParty: "LcaeInitatingPartyLandlord" } : docSecondItem?.contractType === "LCAES_ETCT" ? { LcaeInitatingParty: "LcaeInitatingPartyTenant" } : {}),
      ...(docSecondItem?.contractType ? { ...createdDocItem.transactionData } : {}),
    },
    openFor: ["ERTB", "CB_ERTB", "BDB", "FRASCONA_BDB"].includes(parentClientdocItem.contractType) || docSecondItem?.contractType === "LCAE" ? "BUYER_AGENT" : "SELLER_AGENT",
    RTDInitiator: ["ERTB", "CB_ERTB", "BDB", "FRASCONA_BDB"].includes(parentClientdocItem.contractType) || docSecondItem?.contractType === "LCAE" ? "BUYER" : "SELLER",
    contractId: docSecondItem?.contractId ? docSecondItem?.contractId : parentClientdocItem.contractId,
    contractType: docSecondItem?.contractType ? docSecondItem?.contractType : parentClientdocItem.contractType,
    ...(docSecondItem?.contractType ? { clientDocId: createdDocItem._id } : {}),
    token: localStorage.getItem("userAuth"),
    builtForSection: "DOCUMENT",
    builtForId: getUserDetailsData?._id,
    key: getUserDetailsData?._id,
    signable: true,
  };
  return buildData;
};

export const getMaindocumentStatus = (ele) => {
  const { contractType, createdDocuments } = ele || {};

  if (!createdDocuments || !createdDocuments.length) {
    return { color: "whitesmoke", highlightedDocId: null };
  }

  let fullySignedDoc;
  let color = "yellow";

  // DWR, BDS, BDB contract types
  if (["DWR", "BDS", "BDB", "FRASCONA_BDB", "WFD"].includes(contractType)) {
    const statusKey = (["DWR", "BDS"].includes(contractType))
      ? ["sellerSignStatus", "sellerAgentSignStatus"]
      : contractType === "WFD" ? ["sellerSignStatus"]
      : ["buyerSignStatus", "buyerAgentSignStatus"];

    if (contractType === "WFD") {
      fullySignedDoc = createdDocuments.find((el) => el[statusKey[0]] === "SUBMITTED");
    } else {
      fullySignedDoc = createdDocuments.find((el) => 
        el[statusKey[0]] === "SUBMITTED" && el[statusKey[1]] === "SUBMITTED"
      );
    }

    if (fullySignedDoc) {
      return { color: "green", highlightedDocId: fullySignedDoc._id };
    }
    return { color: "yellow", highlightedDocId: createdDocuments[0]._id };
  }

  // ERTB, ERTL, ETCT contract types
  if (["ERTB", "CB_ERTB", "ERTL", "ETCT"].includes(contractType)) {
    if (["ERTB", "CB_ERTB"].includes(contractType)) {
      // Buying side doc
      fullySignedDoc = createdDocuments.find((el) => 
        el.buyerSignStatus === "SUBMITTED" && el.buyerAgentSignStatus === "SUBMITTED"
      );
    } else if (contractType === "ERTL" || contractType === "ETCT") {
      // Selling side doc
      fullySignedDoc = createdDocuments.find((el) => 
        el.sellerSignStatus === "SUBMITTED" && el.sellerAgentSignStatus === "SUBMITTED"
      );
    }

    if (fullySignedDoc) {
      try {
        const listingEndDate = moment(fullySignedDoc.transactionData?.ListingPeriodEndByDate);

        if (listingEndDate.isValid()) {
          if (moment().isAfter(listingEndDate)) {
            let isAeFullySignedDoc;
            if (["ERTB", "CB_ERTB"].includes(contractType)) {
              // Buying side doc
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.buyerSignStatus === "SUBMITTED" && el.buyerAgentSignStatus === "SUBMITTED"
              );
            } else if (contractType === "ERTL" || contractType === "ETCT") {
              // Selling side doc
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.sellerSignStatus === "SUBMITTED" && el.sellerAgentSignStatus === "SUBMITTED"
              );
            }

            if (isAeFullySignedDoc) {
              const aeListingEndDate = moment(isAeFullySignedDoc.transactionData.ListingPeriodEndByDate);
              if (aeListingEndDate.isValid() && aeListingEndDate.isAfter(moment())) {
                color = "green";
              } else {
                color = "red"; // Document is expired
              }
            } else {
              color = "red"; // Document is expired
            }
          } else {
            let isAeFullySignedDoc;
            if (["ERTB", "CB_ERTB"].includes(contractType)) {
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.buyerSignStatus === "SUBMITTED" && el.buyerAgentSignStatus === "SUBMITTED"
              );
            } else if (contractType === "ERTL" || contractType === "ETCT") {
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.sellerSignStatus === "SUBMITTED" && el.sellerAgentSignStatus === "SUBMITTED"
              );
            }

            if (isAeFullySignedDoc) {
              const aeListingEndDate = moment(isAeFullySignedDoc.transactionData.ListingPeriodEndByDate);
              if (aeListingEndDate.isValid() && aeListingEndDate.isAfter(moment())) {
                color = "green";
              } else {
                color = "red"; // Document is expired
              }
            } else {
              color = "green"; // Document is not expired
            }
          }
        } else {
          color = "yellow"; // Invalid date
        }
      } catch (error) {
        console.error("Error processing date:", error);
        color = "yellow";
      }

      return { color, highlightedDocId: fullySignedDoc._id };
    }
    return { color: "yellow", highlightedDocId: createdDocuments[0]._id };
  }
};


export const checkForDeleteClientDoc = (item) => {
  const { contractType, buyerSignStatus, sellerSignStatus, buyerAgentSignStatus, sellerAgentSignStatus } = item || {};
  if (["BDS", "DWR", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT", "WFD"].includes(contractType)) {
    if (contractType === "WFD" && sellerSignStatus === "SUBMITTED") {
      return false;
    }
    if (sellerSignStatus==="SUBMITTED" &&  sellerAgentSignStatus === "SUBMITTED") {
      return false;
    }
    return true;
  }
  if (["ERTB", "CB_ERTB", "BDB", "LCAE", "FRASCONA_BDB"].includes(contractType)) {
    if (buyerSignStatus==="SUBMITTED" &&  buyerAgentSignStatus === "SUBMITTED" ) {
      return false;
    }
    return true;
  }
};

const postImageRequest = async(data) => {
  let accessTokan = localStorage.getItem("userAuth");
  // const params = new URLSearchParams();
  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "MEMORIES");
  // params.append("fileType", "IMAGE");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}

const compressImage = (file, quality = 0.7, maxWidth = 800, maxHeight = 800) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate new dimensions while maintaining aspect ratio
        let { width, height } = img;
        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;
          if (width > height) {
            width = maxWidth;
            height = maxWidth / aspectRatio;
          } else {
            height = maxHeight;
            width = maxHeight * aspectRatio;
          }
        }

        // Set canvas size and draw image
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas back to a Blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Convert the compressed Blob to a File with the original file's metadata
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: file.lastModified,
              });
              resolve(compressedFile);
            } else {
              reject(new Error("Compression failed"));
            }
          },
          file.type,
          quality
        );
      };
      img.onerror = reject;
      img.src = event.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

  export const processBase64Images = async (notes, hasInsertedImages) => {
    if (!hasInsertedImages) return notes;
  
    const base64ImageRegex = /<img[^>]*src="data:image\/[^;]+;base64,[^"]*"[^>]*>/g;
    const matches = notes.match(base64ImageRegex);
  
    if (matches && matches.length > 0) {
      for (let match of matches) {
        const base64SrcMatch = match.match(/src="(data:image\/[^;]+;base64,[^"]*)"/);
  
        if (base64SrcMatch && base64SrcMatch[1]) {
          const base64Data = base64SrcMatch[1];
  
          // Extract mime type and content
          const mimeType = base64Data.match(/data:(image\/[^;]+);base64,/)[1];
          const base64Content = base64Data.replace(/data:image\/[^;]+;base64,/, "");
          const binaryData = atob(base64Content);
  
          // Convert to binary data
          const arrayBuffer = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            arrayBuffer[i] = binaryData.charCodeAt(i);
          }
  
          // Create a Blob
          const blob = new Blob([arrayBuffer], { type: mimeType });
  
          // Convert Blob to File to include lastModifiedDate
          const fileName = `image-${Date.now()}.${mimeType.split("/")[1]}`;
          const lastModified = Date.now(); // Current timestamp as lastModified
          const file = new File([blob], fileName, { type: mimeType, lastModified });
  
          // Compress the image
          const fileData = await compressImage(file);
  
          // Upload image and get URL
          const awsUrlLink = await postImageRequest(fileData);
          const randomId = Math.floor(10000 + Math.random() * 90000);
  
          // Replace base64 image with the uploaded image URL
          notes = notes.replace(
            match,
            `<img src="${awsUrlLink?.data?.info?.fileData?.fileUrl}" alt="${randomId}" height="25px" width="25px" style="margin-right: 20px;">`
          );
        }
      }
    }
  
    return notes;
  };
