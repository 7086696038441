import { documentConstants } from "../action-types";

const initialState = {
  urlImportData: {},
  CurrentPdfImportData: {},
  urlImportError: null,
  urlImportErrorAccured: false,
  pdfImportError: false,
  pdfImportLoading: false
};

const urlImportReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.PDF_IMPORT_LOADING:
        return {
          ...state,
          pdfImportLoading: true,
        };
    case documentConstants.URL_IMPORT_SUCCESS:
      return {
        ...state,
        urlImportData: action.payload,
        CurrentPdfImportData: action.payload,
        urlImportError: null,
        pdfImportLoading: false,
      };
    case documentConstants.URL_IMPORT_ERROR:
      return {
        ...state,
        urlImportData: {},
        CurrentPdfImportData: {},
        urlImportError: action.payload,
        pdfImportLoading: false,
        urlImportErrorAccured: action.urlImportErrorAccured,
      };
    case documentConstants.PDF_IMPORT_ERROR:
      return {
        ...state,
        pdfImportError: action.pdfImportError,
        pdfImportLoading: false,
      };
    case documentConstants.SET_PDF_IMPORT_STATE:
      return {
        ...state,
        CurrentPdfImportData:{...state.CurrentPdfImportData,  ...action.payload},
      };
    case documentConstants.URL_IMPORT_CLEAR_SUCCESS:
      return {
        ...state,
      ...initialState
      };

    case documentConstants.URL_IMPORT_ERROR_CLEAR:
      return {
        ...state,
        urlImportErrorAccured: false,
      };

    default:
      return state;
  }
};
export { urlImportReducer };
