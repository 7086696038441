import { clientConstants } from "../action-types";

const initialState = {
  globalClientLoading: false,

  personDetails: [],
  personDetailsLoading: false,
  personDetailsError: null,

  personDocs: [],
  personDocsLoading: true,
  personDocsError: null,
  triggerGetPersonDocs: [],
  renderPersonDetail: [],

  isAddNew: false
};

const personDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    // client details
    case clientConstants.RENDER_PERSON_DETAIL:
      return {
        ...state,
        renderPersonDetail: []
      };
    case clientConstants.CLIENT_DETAILS_LOADING:
      return {
        ...state,
        personDetails: [],
        personDetailsLoading: true,
        personDetailsError: false,
      };
    case clientConstants.CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        personDetails: action.personDetails,
        personDetailsLoading: false,
        personDetailsError: false,
      };
    case clientConstants.CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        personDetailsError: action.personDetailsError,
        personDetailsLoading: false,
      };
    case clientConstants.PERSONALDATA_RESET:
      return {
        ...state,
        personDetails: [],
        personDetailsLoading: true,
        personDetailsError: null,
      };
    //   Get person Docs
    case clientConstants.PERSON_DOCS_LOADING:
      return {
        ...state,
        personDocs: action.personDocs,
        personDocsLoading: true,
        globalClientLoading: true,
        personDocsError: false,
      };
    case clientConstants.PERSON_DOCS_SUCCESS:
      return {
        ...state,
        personDocs: action.personDocs,
        personDocsLoading: false,
        personDocsError: false,
      };
    case clientConstants.PERSON_DOCS_FAILURE:
      return {
        ...state,
        personDocsError: action.personDocsError,
        personDocsLoading: false,
      };
    case clientConstants.TRIGGER_GET_PERSON_DOCS:
      return {
        ...state,
        triggerGetPersonDocs: [],
      };
    case clientConstants.IS_ADD_NEW:
      return {
        ...state,
        isAddNew: true
      }
    case clientConstants.RESET_IS_ADD_NEW:
      return {
        ...state,
        isAddNew: false
      }
    default:
      return state;
  }
};
export { personDetailReducer };
