import { Tooltip, Typography } from "antd";
import React from "react";
import { EmailDetails } from "./EmailDetails";
import styles from "../styles/Client.module.css";
import { MailOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { EditEmailDetails } from "./EditEmailDetails";

const Email = ({ setModelDrawer, personDetails, source, onFinish,onDeleteEmail }) => {
 const location = useLocation();
 const {Text} = Typography;
  useEffect(() => {
    if (location.state?.isemailopen === true) {
      setModelDrawer({
        status: true,
        component: (
          <EmailDetails
            data={personDetails}
            onFinish={onFinish}
          />
        ),
        title: "EMAIL DETAILS",
        toolTip: "Add Email"
      })
    }

  }, [location.pathname, setModelDrawer]);
  
  const emails = personDetails?.emails?.length > 0
  ? personDetails.emails
  : personDetails?.email?[{
      email: personDetails?.email || "",
      isDefault: true,
      type:"PERSONAL"
    }]:[];
  if (source) {
    var textStyle = {
      color:"red",
      fontSize:'smaller',
      whiteSpace:'nowrap',
      marginLeft:'-15px',
    }
    var iconStyle = {
      display:'none',
    }
    var outerTextStyle = {
      display: "inline-block",
    }
  } else {
    textStyle = {
      color:"red",
      whiteSpace:'nowrap',
    }
    var iconStyle = {
      marginRight:'1rem',
      fill:'#000000',
      fontSize:'1.3rem',
    }
    var outerTextStyle = {
      display: "flex",
      alignItems: "center"
    }
  }

  return (
    <Text style={outerTextStyle}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Email ID"} placement="left">
          <MailOutlined style={iconStyle} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={(e) => {
            e.stopPropagation()
            setModelDrawer({
              status: true,
              component: source === "transaction" ? <EmailDetails data={emails} onFinish={onFinish} /> : <EditEmailDetails data={emails} onFinish={onFinish} onDeleteEmail={onDeleteEmail} />,
              title: "Email Details",
              toolTip: "Add Email",
              source:source === "transaction"?"transaction":""
            })
          } 
        }
      >
        <div style={{ marginLeft: "-10%" }} className={styles.iconsclient}>
          <img src="icons/client-details-email.svg" alt=""></img>
        </div>
        <div
          className={styles.userfeild}
          style={{
            ...(personDetails?.email ? { textTransform: "lowercase" } : {}),
          }}
        >
          {personDetails?.email ? (
            <Text className={styles.useremailfeild} ellipsis="rows" copyable>
              {personDetails?.email}
            </Text>
          ) : (
            <span style={source === "transaction" ? textStyle : {}} className={source === "transaction" ? "" : styles.usernoemailfeild}>
              Enter Email Address
            </span>
          )}
        </div>
      </div>
    </Text>
  );
};

export { Email };
