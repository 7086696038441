import { accountPageConstants } from "./action-types";

const initialState = {
  filteredFranchiseData: [],
  filteredBrokrageData: [],
  filteredOfficeData: [],

  delegateListLoading: true,
  delegateListError: false,
  delegateListData: [],

  getDelegateDataLoading: false,
  getDelegateDataError: false,
  getDelegateData: [],

  getFranchiseData: [],
  getFranchiseLoading: false,
  getFranchiseError: false,

  getBrokrageData: [],
  getBrokrageLoading: false,
  getBrokrageError: false,

  getOfficeData: [],
  getOfficeLoading: false,
  getOfficeError: false,

  uploadImageData: [],
  uploadImageLoading: true,
  uploadImageError: null,

  userProfileData: [],
  userProfileLoading: true,
  userProfileError: null,

  getUserDetailsData: [],
  userId: null,
  userName: null,
  getUserDetailsLoading: true,
  getUserProfileDataLoading: false,
  getUserDetailsError: null,

  updatePasswordData: null,
  updatePasswordLoading: true,
  updatePasswordError: null,

  updateNameDetails: null,
  updateNameLoading: true,
  updateNameError: null,

  updateAddressDetails: null,
  updateAddressLoading: true,
  updateAddressError: null,

  createLicenseData: null,
  createLicenseLoading: false,
  createLicenseError: false,

  getLicenseData: null,
  getLicenseLoading: false,
  getLicenseError: false,
  licenseid:false,

  getUserSavedNotesData: null,


  accessData: null,

  wholeAppLoading: false,
  wholeAppError: false,

  logsLoading: true,
  logsError: false,
  logsData: [],

  safeTDriveLoading: false,
  safeTDriveError:false,
  safeTDriveData: [],
  safeTDriveTotalData: 0,

  createdSafeTDriveData:[],
  createdOneCBSRSafeTDriveData:[],
  searchedResultSafeTDriveData:null,

  searchedText: "",
  dateSearch : { startingDate: null, endingDate: null },
  sortBy:"",

  acceptedDelegateData: [], submittedDelegateData: [], rejectedDelegateData: [], draftDelegateData: [],
  activeDelegateData: [], inprogressDelegateData: [], inactiveDelegateData: [], draftSellingDelegateData: [],
  delegatePartialDataLoading : false,
  delegatePartialDataError : false,

  pageNumberInZip:1,

  AwsBufferDataSafeTDrive:"",
  subscriptionExists: false,
  subscriptionRequired: true,

  updateDelegate: [],

  emailSignLoading:false,
  emailSignData:[],
  emailsignError:false,
  isLoadSignApi:false,

  editBrokerageLoading: false,
  agentAndDelegates: [],

  safetyDriveDownloadStatus : false,

  ctmTransferProgress : 0
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case accountPageConstants.UPDATE_DELEGATE_SUCCESS:
      return {
        ...state,
        updateDelegate: []
      }
    case accountPageConstants.GET_BROKRAGE_DETAILS_LOADING:
      return {
        ...state,
        getBrokrageLoading: action.getBrokrageLoading,
        getBrokrageError: false,
      };
    case accountPageConstants.GET_BROKRAGE_DETAILS_SUCCESS:
      return {
        ...state,
        getBrokrageData: action.getBrokrageData,
        getBrokrageLoading: action.getBrokrageLoading,
        getBrokrageError: false,
      };
    case accountPageConstants.GET_BROKRAGE_DETAILS_FAILURE:
      return {
        ...state,
        getBrokrageLoading: false,
        getBrokrageError: true,
      };
    // get all Licenses
    case accountPageConstants.GET_FRANCHISE_DETAILS_LOADING:
      return {
        ...state,
        getFranchiseLoading: action.getFranchiseLoading,

      };
    case accountPageConstants.GET_FRANCHISE_DETAILS_SUCCESS:
      // let onlyFranchiseData = [],
      //   onlyBrokerageData = [],
      //   onlyOfficeData = [];
      // if (action.getFranchiseData.length) {
      //   action.getFranchiseData.forEach((el) => {
      //     if (el.officeType === "FRANCHISE") {
      //       onlyFranchiseData.push(el);
      //     } else if (el.officeType === "BROKERAGE") {
      //       onlyBrokerageData.push(el);
      //     } else {
      //       onlyOfficeData.push(el);
      //     }
      //   });
      // }
      return {
        ...state,
        // filteredofficeData: onlyFranchiseData,
        // filteredBrokrageData: onlyBrokerageData,
        // filteredOfficeData: onlyOfficeData,
        getFranchiseLoading: false,
        getFranchiseError: false,
        getFranchiseData: action.getFranchiseData,

      };
    case accountPageConstants.GET_FRANCHISE_DETAILS_FAILURE:
      return {
        ...state,
        getFranchiseLoading: false,
        getFranchiseError: true,
      };

      case accountPageConstants.GET_OFFICE_DETAILS_LOADING:
        return {
          ...state,
          getOfficeLoading:action.getOfficeLoading,
          getOfficeError:false
        }
        case accountPageConstants.GET_OFFICE_DETAILS_SUCCESS:
          return {
            ...state,
            getOfficeData:action.getOfficeData,
            getOfficeLoading:action.getOfficeLoading,
            getOfficeError:false,
          }
          case accountPageConstants.GET_OFFICE_DETAILS_FAILURE:
            return {
              ...state,
              getOfficeLoading:false,
              getOfficeError:true
            }

    // image upload
    case accountPageConstants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageData: action.uploadImageData,
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: null,
      };
    case accountPageConstants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadImageData: [],
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: action.uploadImageError,
      };
    // Profile update
    case accountPageConstants.USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileData: action.userProfileData,
        userProfileLoading: action.userProfileLoading,
        userProfileError: null,
      };
    case accountPageConstants.USER_PROFILE_FAILURE:
      return {
        ...state,
        userProfileData: [],
        userProfileLoading: action.userProfileLoading,
        userProfileError: action.userProfileError,
      };

    // Get Profile Details
    case accountPageConstants.GET_USER_DETAILS_LOADING:
      return {
        ...state,
        getUserProfileDataLoading: true,
        getUserDetailsError: null,
      };
    case accountPageConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        getUserDetailsData: action.getUserDetailsData,
        userId: action.userId,
        userName: action.userName,
        getUserDetailsLoading: false,
        getUserProfileDataLoading: false,
        getUserDetailsError: null,
        subscriptionExists: action.subscriptionExists,
        subscriptionRequired: action.subscriptionRequired,
        roleAccessed: action.roleAccessed || {},
        agentAndDelegates: action.agentAndDelegates
      };
    case accountPageConstants.SELECT_AGENT:
      state?.agentAndDelegates?.forEach((ele, idx)=>{
        if(ele._id === action.selectedAgent._id){
          state.agentAndDelegates[idx].selected = action.selectedAgent.selected
        }
      })
      return {
        ...state

      }
    case accountPageConstants.CHECK_ALL_AGENTS:
      state?.agentAndDelegates?.forEach((ele, idx)=>{
        state.agentAndDelegates[idx].selected = true
      })
      return {
        ...state
      }
    case accountPageConstants.REPLACE_ALL_AGENTS:
      return {
        ...state,
        agentAndDelegates: action.agentAndDelegates
      }
    case accountPageConstants.GET_USER_SAVED_NOTES_SUCCESS:
      return {
        ...state,
        getUserSavedNotesData: action.payload,
        getUserDetailsLoading: false,
        getUserDetailsError: null,
      };
    case accountPageConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        getUserDetailsData: [],
        getUserDetailsLoading: false,
        getUserProfileDataLoading: false,
        getUserDetailsError: action.getUserDetailsError,
      };
    // person update password
    case accountPageConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordData: action.updatePasswordData,
        updatePasswordLoading: false,
        updatePasswordError: null,
      };
    case accountPageConstants.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        updatePasswordLoading: true,
        updatePasswordError: action.updatePasswordError,
      };

    case accountPageConstants.RESET_ACCOUNT_PAGE:
      return {
        ...state,
        userProfileData: [],
        userProfileLoading: true,
        userProfileError: null,
      };
    case accountPageConstants.RESET_UPDATE_PASSWORD:
      return {
        ...state,
        updatePasswordData: null,
        updatePasswordLoading: true,
        updatePasswordError: null,
      };

    // Edit name
    case accountPageConstants.EDIT_NAME_SUCCESS:
      return {
        ...state,
        updateNameDetails: action.updateNameDetails,
        updateNameLoading: action.updateNameLoading,
      };
    case accountPageConstants.EDIT_NAME_FAILURE:
      return {
        ...state,
        updateNameError: action.updateNameError,
        updateNameLoading: action.updateNameLoading,
      };

    // Update Address data
    case accountPageConstants.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updateAddressDetails: action.updateAddressDetails,
        updateAddressLoading: action.updateAddressLoading,
      };
    case accountPageConstants.UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        updateAddressError: action.updateAddressError,
        updateAddressLoading: action.updateAddressLoading,
      };

    // reset name
    case accountPageConstants.CLIENT_NAME_RESET:
      return {
        ...state,
        updateNameDetails: null,
        updateNameLoading: true,
        updateNameError: null,
      };

    // address reset
    case accountPageConstants.CLIENT_ADDRESS_RESET:
      return {
        ...state,
        updateAddressDetails: null,
        updateAddressLoading: true,
        updateAddressError: null,
      };

    // Create a new License
    case accountPageConstants.CREATE_LICENCE_LOADING:
      return {
        ...state,
        createLicenseLoading: true,
        createLicenseError: false,
      };
    case accountPageConstants.CREATE_LICENCE_SUCCESS:
      return {
        ...state,
        createLicenseData: action.createLicenseData,
        createLicenseLoading: false,
        createLicenseError: false,
      };
    case accountPageConstants.CREATE_LICENCE_FAILURE:
      return {
        ...state,
        createLicenseLoading: false,
        createLicenseError: action.createLicenseError,
      };

    // get all Licenses
    case accountPageConstants.GET_LICENCE_LOADING:
      return {
        ...state,
        getLicenseLoading: true,
        getLicenseError: false,
      };
    case accountPageConstants.GET_LICENCE_SUCCESS:
      // const key = "idType";
      // let uniqueLicenceTypeData = [
      //   ...new Map(
      //     action.getLicenseData.map((item) => [item[key], item])
      //   ).values(),
      // ];
      return {
        ...state,
        getLicenseData: action.getLicenseData,
        licenseid:true,
        getLicenseLoading: false,
        getLicenseError: false,
      };
    case accountPageConstants.GET_LICENCE_FAILURE:
      return {
        ...state,
        getLicenseLoading: false,
        getLicenseError: action.getLicenseError,
      };

    // Update License data
    case accountPageConstants.UPDATE_LICENCE_SUCCESS:
      return {
        ...state,
        createLicenseData: action.updateLicenseData,
        createLicenseLoading: false,
        createLicenseError: false,
      };
    // delete license
    case accountPageConstants.DELETE_LICENCE_SUCCESS:
      let marklicense=null
      if (state.getLicenseData) {
        marklicense = true;
      } else {
        marklicense = false;
      }
      return {
        ...state,
        createLicenseData: [],
        createLicenseLoading: false,
        createLicenseError: false,
        licenseid:marklicense
      };
    case accountPageConstants.GET_USER_ACCESS_DATA:
      return {
        ...state,
        accessData: action.accessData,
      };
    case accountPageConstants.WHOLE_APP_LOADING:
      return {
        ...state,
        wholeAppLoading: action.wholeAppLoading,
        wholeAppError: false,
      };
    case accountPageConstants.WHOLE_APP_ERROR:
      return {
        ...state,
        wholeAppLoading: false,
        wholeAppError: true
      }
    case accountPageConstants.GET_LOG_LOADING:
      return {
        ...state,
        logsLoading: true,
        logsError: false
      }
    case accountPageConstants.GET_LOG_ERROR:
      return {
        ...state,
        logsLoading: false,
        logsError: true
      }
    case accountPageConstants.GET_LOG_DATA:
      return {
        ...state,
        logsLoading: false,
        logsError: false,
        logsData: action.logsData
      }
      // Safe-t-driveGET_SAFE_T_DRIVE_LOADING
    case accountPageConstants.GET_SAFE_T_DRIVE_LOADING:
      return {
        ...state,
        safeTDriveLoading: true,
        safeTDriveError: false
      }
    case accountPageConstants.GET_SAFE_T_DRIVE_ERROR:
      return {
        ...state,
        safeTDriveLoading: false,
        safeTDriveError: true
      }
    case accountPageConstants.GET_SAFE_T_DRIVE_SUCCESS:
      return {
        ...state,
        safeTDriveLoading: false,
        safeTDriveError: false,
        safeTDriveData: action?.safeTDriveData ,
        safeTDriveTotalData:action?.safeTDriveTotalData,
        createdSafeTDriveData:[],
        createdOneCBSRSafeTDriveData:[],
        safetyDriveDownloadStatus: action?.safetyDriveDownloadStatus
      }

    case accountPageConstants.SET_DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        safetyDriveDownloadStatus: action.safetyDriveDownloadStatus
      }

    case accountPageConstants.CREATE_SAFE_T_DRIVE_SUCCESS:
      return {
        ...state,
        safeTDriveLoading: false,
        safeTDriveError: false,
        createdSafeTDriveData: action?.createdSafeTDriveData
      }

    case accountPageConstants.UPDATE_SELECTED_SAFE_T_DRIVE_SUCCESS:
      return {
        ...state,
        safeTDriveLoading: false,
        safeTDriveError: false,
        createdOneCBSRSafeTDriveData: action?.createdOneCBSRSafeTDriveData
      }

    case accountPageConstants.SEARCH_SAFE_T_DRIVE_SUCCESS:
      return {
        ...state,
        safeTDriveLoading: false,
        safeTDriveError: false,
        safeTDriveData: action?.searchedResultSafeTDriveData
      }

    case accountPageConstants.GET_AWS_SAFE_T_DRIVE_SUCCESS:
      return {
        ...state,
        AwsBufferDataSafeTDrive: action?.AwsBufferDataSafeTDrive,
        // safeTDriveLoading: false,
        // safeTDriveError: false,
      }

    case "REMOVE_AWS_DATA_SAFE_T_DRIVE_SUCCESS":
      return {
        ...state,
        AwsBufferDataSafeTDrive: "",
      }

    case "HANDLE_PAGINATION_IN_ZIP":
      return {
        ...state,
        pageNumberInZip: action?.pageNumberInZip
      }
    case accountPageConstants.UPDATE_SEARCHED_TEXT_SUCCESS:
      return {
        ...state,
        searchedText: action?.searchedText
      }
    case accountPageConstants.UPDATE_DATE_SEARCH_SUCCESS:
      return {
        ...state,
        dateSearch: action?.dateSearch
      }

    case accountPageConstants.UPDATE_SORTBY_SUCCESS:
      return {
        ...state,
        sortBy: action?.sortBy
      }

      //for get delegates
      case accountPageConstants.GET_DELEGATE_LIST_LOADING:
        return {
          ...state,
          delegateListLoading: true,
          delegateListError: null,
        };
      case accountPageConstants.GET_DELEGATE_LIST_SUCCESS:
        return {
          ...state,
          delegateListData: action.getDelegateListData,
          delegateListLoading: false,
          delegateListError: null,
        };
        case accountPageConstants.CLEAR_DELEGATES_LIST:
        return {
          ...state,
          delegateListData: [],
          delegateListLoading: false,
          delegateListError: null,
        };

      case accountPageConstants.GET_DELEGATE_LIST_ERROR:
        return {
          ...state,
          delegateListData: [],
          delegateListLoading: false,
          delegateListError: null,
        };
        case accountPageConstants.GET_PARTIAL_DATA_LOADING:
          return {
            ...state,
            delegatePartialDataLoading:true,
            delegatePartialDataError:false
          }
        case accountPageConstants.GET_PARTIAL_DATA_ERROR:
          return {
            ...state,
            delegatePartialDataLoading:false,
            delegatePartialDataError:true
          }
        case accountPageConstants.GET_PARTIAL_DATA_SUCCESS:
          let acceptedDelegateData = [], submittedDelegateData = [], rejectedDelegateData = [], draftDelegateData = [];
          let activeDelegateData = action?.delegatePartialData?.selling?.active || [],
          inprogressDelegateData = action?.delegatePartialData?.selling?.pendiing || [],
          inactiveDelegateData = action?.delegatePartialData?.selling?.closed || [],
          draftSellingDelegateData = action?.delegatePartialData?.selling?.draft || [];

          action?.delegatePartialData?.buying?.forEach(element => {
            if(element?.title === "accepted"){
              acceptedDelegateData = element?.data || [];
            }
            else if(element?.title === "submitted"){
              submittedDelegateData = element?.data || [];
            }
            else if(element?.title === "rejected"){
              rejectedDelegateData = element?.data || [];
            }
            else if(element?.title === "draft"){
              draftDelegateData = element?.data || [];
            }
          });
          return {
            ...state,
            delegatePartialDataLoading:false,
            delegatePartialDataError:false,
            acceptedDelegateData,
            submittedDelegateData,
            rejectedDelegateData,
            draftDelegateData,
            activeDelegateData,
            inprogressDelegateData,
            inactiveDelegateData,
            draftSellingDelegateData
          }

        case accountPageConstants.GET_DELEGATE_DATA_LOADING:
            return {
              ...state,
              getDelegateDataLoading: true,
              getDelegateDataError: null,
            };
        case accountPageConstants.GET_DELEGATE_DATA_SUCCESS:
            return {
              ...state,
              getDelegateData: action.getDelegateData,
              getDelegateDataLoading: false,
              getUserProfileDataLoading: false,
              getDelegateDataError: null,
            };
        case accountPageConstants.GET_DELEGATE_DATA_ERROR:
              return {
                ...state,
                getDelegateDataLoading:false,
                getDelegateDataError:true
              };
        case accountPageConstants.RESET_DELEGATE_DATA:
                return {
                  ...state,
                  getDelegateData:[]
                }
        case accountPageConstants.GET_EMAIL_SIGN_DATA_LOADING:
            return {
              ...state,
              emailSignLoading: true,
              emailsignError: false,
              isLoadSignApi:false
            };
        case accountPageConstants.GET_EMAIL_SIGN_DATA_SUCCESS:
            return {
              ...state,
              emailSignData: action.emailSignData,
              emailSignLoading: false,
              emailsignError: false,
              isLoadSignApi:false
            };
        case accountPageConstants.GET_EMAIL_SIGN_DATA_ERROR:
              return {
                ...state,
                emailSignLoading:false,
                emailsignError:true,
                isLoadSignApi:false
              };
        case accountPageConstants.DELETE_EMAIL_SIGN_LOADING:
                return {
                  ...state,
                  emailSignLoading: true,
                  emailsignError: false,
                  isLoadSignApi:false,
                };
        case accountPageConstants.DELETE_EMAIL_SIGN_SUCCESS:
                return {
                  ...state,
                  emailSignLoading: false,
                  emailsignError: false,
                  isLoadSignApi:true
                };
        case accountPageConstants.DELETE_EMAIL_SIGN_ERROR:
                  return {
                    ...state,
                    emailSignLoading:false,
                    emailsignError:true,
                    isLoadSignApi:false
                  };
        case accountPageConstants.UPLOAD_EMAIL_SIGN_LOADING:
                    return {
                      ...state,
                      emailSignLoading: true,
                      emailsignError: false,
                      isLoadSignApi:false,
                };
        case accountPageConstants.UPLOAD_EMAIL_SIGN_SUCCESS:
                    return {
                      ...state,
                      emailSignLoading: false,
                      emailsignError: false,
                      isLoadSignApi:true,
                };
        case accountPageConstants.UPLOAD_EMAIL_SIGN_ERROR:
                      return {
                        ...state,
                        emailSignLoading:false,
                        emailsignError:true,
                        isLoadSignApi:false
                };
        case accountPageConstants.EDIT_BROKERAGE_LOADING:
          return {
            ...state,
            editBrokerageLoading:true,
          }
        case accountPageConstants.EDIT_BROKERAGE_ERROR:
          return {
            ...state,
            editBrokerageLoading:false,
          }
        case accountPageConstants.EDIT_BROKERAGE_SUCCESS:
          return {
            ...state,
            editBrokerageLoading:false,
          }
    case accountPageConstants.SET_CTM_TRANSFER_PROGRESS:
      return {
        ...state,
        ctmTransferProgress: action.showProgress,
      }

    default:
      return state;
  }
};
export { account };
