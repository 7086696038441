import { Tooltip, Typography } from "antd";
import React from "react";
import { BiUser } from "react-icons/bi";
import styles from "../styles/Client.module.css";
import { capitalizeStr } from "../../Common/utils/extraFunctions";
import { NickNameDetails } from "./NickNameDetails";
import { UserSwitchOutlined } from "@ant-design/icons";
const NickName = ({ setModelDrawer, personDetails, onFinish, isAddNew,selectedClient }) => {
 const nickName= personDetails?.nicknames?.length > 0
 ? personDetails.nicknames
 :[]

  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Nickname"} placement="left">
          <UserSwitchOutlined  className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              
              <NickNameDetails
                onFinish={onFinish}
                setModelDrawer={setModelDrawer}
                data={nickName}
                isAddNew={isAddNew}
                selectedClient={selectedClient}
              />
            ),
            title: "Nickname Details",
            toolTip: "Add Another Name"
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-name.svg" alt=""></img>
        </div>
        <div style={{ marginLeft: "-10%" }} className={styles.userfeild}>
        {
    // Find the default nickname (if exists)
    
    personDetails?.nicknames?.find((nickname) => nickname.isDefault === true)
      ? capitalizeStr(
          personDetails?.nicknames?.find((nickname) => nickname.isDefault === true).name
        )
      : "Enter Nickname" // If no default nickname, display "Enter Nickname"
  }
  </div>
      </div>
    </Text>
  );
};

export { NickName };
