import React, { useEffect, useState } from "react";
import {
  Button,
  Avatar,
  List,
  Modal,
  Space,
  Typography,
  Image,
  Row,
  Col,
  Dropdown,
  Form,
  Menu,
  Popconfirm,
  Input,
  Select,
  Pagination,
} from "antd";
import { StarOutlined,DeleteOutlined,MoreOutlined,InboxOutlined ,CloseCircleOutlined,SearchOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/Listing.module.css";
import { EmptyData } from "../../Common/components/EmptyData";
import { bugReportHandle } from "../state/actions";
import Dragger from "antd/lib/upload/Dragger";
import { useRef } from "react";
import {Loading} from "../../Common/components/Loading"
import { capitalizeStr, roleAccessFunc } from '../../Common/utils/extraFunctions';
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import imageCompression from 'browser-image-compression';
import EditBugModal from "./EditBugModal";

const { Title,Text } = Typography;

function SideBarRight() {
  const { myBugLlistingData, myBugLlistingLoading,usersData,usersDataLoading,totalUsersRecords, searchbugdata, bugPostLoading } = useSelector(
    (state) => state.bugReport
  );
  const [clientcount, setClientCount] = useState(7);
  const [handleModal, setHandleModal] = useState(false);
  const [handleData, setHandleData] = useState([]);
  const [UpdateImagedata,setUpdateimagedata]=useState([])
  const [modalVisible,setModalVisible]=useState(false)
  const imageFormdataRef = useRef([]);
  const [role,setRole] = useState(false);
  const [userModalVisible,setUserModalVisible] = useState(false)
  const [input, setInput] = useState("");
  const [clientID,setClientID] = useState("")
  const [userDataPage, setUserDataPage] = useState(1);
  const [searchUserdata,setSearchUserData] = useState("");
  const {renderComp} = useSelector(state=>state.bugReport);
  const { getUserDetailsData, roleAccessed } = useSelector((state) => state.account);
  const [bufferData, setbufferData] = useState([]);
  const [typeChanged, setTypeChanged] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const spot1 = useMediaQuery({ minWidth:481,maxWidth: 775 });
  const quillRef = useRef();
  const isTab1 = useMediaQuery({ maxWidth: 991 });
  const spot6 = useMediaQuery({ minWidth:481,maxWidth: 775 });
  const spot7 = useMediaQuery({ minWidth:481,maxWidth: 775 });
  const spot8 = useMediaQuery({ minWidth:980});
  const isTextPasteRef = useRef(false)

  const dispatch=useDispatch()

  const handleDelete=(id)=>{
    if(renderComp==='listings'){
        dispatch(bugReportHandle.bugDelete(id,"BUGS",true))
    }else{
        if(renderComp === "suggestions"){
          dispatch(bugReportHandle.bugDelete(id,"SUGGESTIONS",true))
        }else{
          dispatch(bugReportHandle.bugDelete(id,"SUPPORT",true))
        }
    }
    setHandleModal(false)
  }

  const handleShowDeleteModal=(id)=>{
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk:()=>handleDelete(id)
    });
  }

  const getColorForStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return "red";
      case "IN_PROGRESS":
        return "#DFC103";
      case "NEEDS_TESTING":
        return "orange";
      case "AWAITING_INPUT":
        return "purple"
      case "INPUT_RECEIVED":
        return "#33CEFF"
      case "COMPLETED":
        return "blue";
      case "CLOSED":
        return "green";
      default:
        return "black";
    }
  };


  const IconText = ({ icon, text, item, handle }) => (
    <Space
      // onClick={() => handle(item)}
      style={{ color: getColorForStatus(text) }}
    >
      {React.createElement(icon)}
      {text === "NEEDS_TESTING" ? "COMPLETED/NEEDS TESTING" : text === "AWAITING_INPUT" ? "AWAITING-INPUT" : text === "INPUT_RECEIVED" ? "INPUT-RECEIVED" : text === "IN_PROGRESS" ? "IN-PROGRESS" : text}

    </Space>
  );

  let handleModalData = (item, modal) => {
    setHandleModal(modal);
    setHandleData(item);
    dataArr=[]
  };
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 24,
        },
        md:{
          span:24,
        },
        lg:{
          span:24
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 24,
        },
        md:{
          span:24,
        },
        lg:{
          span:24,
        },
      },
  };

  const priorityOptions = [
    {value:"LOW",label:"Low"},
    {value:"MEDIUM",label:"Medium"},
    {value:"HIGH",label:"High"},
    {value:"CRITICAL",label:"Critical"},
  ]

  const typeOptions = [
    {value:"BUGS",label:"BUGS"},
    {value:"SUGGESTIONS",label:"SUGGESTIONS"},
    {value:"SUPPORT",label:"SUPPORT"},
  ]

  const deleteBug = ({item}) =>{
    if(renderComp==='listings'){
        dispatch(bugReportHandle.bugDelete(item?._id,"BUGS",true))
    }else{
        if(renderComp === "suggestions"){
          dispatch(bugReportHandle.bugDelete(item?._id,"SUGGESTIONS",true))
        }else{
          dispatch(bugReportHandle.bugDelete(item?._id,"SUPPORT",true))
        }
    }
  }
  const MenuItem = (item) => {
    return (
      <Menu size="large">
        <Menu.Item key="1" onClick={()=>{
            handleModalData([item?.item],false);
            setModalVisible(true);
            setUpdateimagedata([])
            imageFormdataRef.current = [];
            }}>
          <span style={{ color: "#04396B" }}>{renderComp === "listings" ? "EDIT BUG" : renderComp === "suggestions" ? "EDIT SUGGESTION" : "EDIT TICKET"}</span>
        </Menu.Item>
        <Menu.Item key="2">

        <Popconfirm
          placement="top"
          title={"Are you sure you want to delete this bug?"}
          onConfirm={()=>deleteBug(item)}
          okText="Yes"
          cancelText="No"
        >
          <span style={{ color: "#04396B" }}>{renderComp === "listings" ? "DELETE BUG" : renderComp === "suggestions" ? "DELETE SUGGESTION" : "DELETE TICKET"}</span>
          </Popconfirm>
        </Menu.Item>
          {((roleAccessFunc(roleAccessed, "CLOSE_BUGS_TICKET")) || role) && item?.item?.status === "ACTIVE" ? <Menu.Item key="3" onClick={() => handleClose(item?.item?._id)}>
          <span style={{ color: "#04396B" }}>{renderComp === "listings" ? "CLOSE BUG" : renderComp === "suggestions" ? "CLOSE SUGGESTION" : "CLOSE TICKET"}</span>
        </Menu.Item> : null}
      </Menu>
    );
  };

  const handleQuillPaste = (e) => {
    const clipboardData = e.clipboardData;
    const types = clipboardData?.types || [];
    isTextPasteRef.current = true;
    if (types?.includes('Files') && types?.length === 1) {
      e.preventDefault();
      isTextPasteRef.current = false;
    }
  }

  const handleClose = (id) => {
    if(renderComp==='listings'){
        dispatch(bugReportHandle.changeStatus(id,"BUGS",true))
    }else{
        if(renderComp === "suggestions"){
          dispatch(bugReportHandle.changeStatus(id,"SUGGESTIONS",true));
        }else{
          dispatch(bugReportHandle.changeStatus(id,"SUPPORT",true));
        }
    }
  }

  let clearForm = (form) => {
    form.resetFields();
    setHandleData([])
};

const handleCancel=(source)=>{
  if(source?.length){
    clearForm(form);
    setModalVisible(false)
    setUpdateimagedata([])
    imageFormdataRef.current = [];
  }else{
    Modal.confirm({
      title:`Are you sure you want to discard updating this ${renderComp === "suggestions"
      ? "suggestion"
      : renderComp === "listings"
      ? "bug"
      : "ticket"}?`,
      okText:"Yes",
      cancelText:"No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        clearForm(form);
        setModalVisible(false)
        setUpdateimagedata([])
        imageFormdataRef.current = [];
      },
      onCancel() {},
    })
  }
}

  const handlescroll=(e)=>{
    const element=e.target
   if (element.scrollHeight - element.scrollTop-50 <= element.clientHeight+1  ) {
    setClientCount(clientcount + 3);
}}


const handleChangeImage = (urlToDelete) => {
    const updatedImageData = handleData.filter((element) => {
      if (element.imageUrls && element.imageUrls.includes(urlToDelete)) {
        element.imageUrls = element.imageUrls.filter((url) => url !== urlToDelete);
      }
      return true;
    });

    setHandleData(updatedImageData);
  };

  const handleUserModalCancel = () => {
    setUserModalVisible(false)
  }

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/JPG" || file.type === "image/WEBP";
    if (!isJpgOrPng) {
      throw new Error("You can only upload JPG/PNG/JPEG/WEBP file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 20;
    if (!isLt5M) {
      throw new Error("Image must be smaller than 20MB!");
    }
    return isJpgOrPng && isLt5M;
  }

  // Handling pasting an image
 function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  let handleCustome = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {
      const newFile = {
        name: data.file.name,
        size: bytesToSize(data.file.size),
        file: reader.result,
        origin: "notBase64",
      };
      setUpdateimagedata((prevFiles) =>{
        if (prevFiles) {
            return [...prevFiles, newFile];
          } else {
            return [newFile];
          }
    });
    };
    reader.onerror = (error) => {
      return error;
    };
    imageFormdataRef.current = [...imageFormdataRef.current,data.file]
  };

  let handleAddFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.addEventListener("change", handleFilesSelected);
    input.click();
  };


let handleFilesSelected = (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const newFile = {
          name: file.name,
          size: bytesToSize(file.size),
          file: reader.result,
          origin: "notBase64",
        };
        setUpdateimagedata((prevFiles) => [...prevFiles, newFile]);
      };
      reader.onerror = (error) => {
        return error;
      };
      imageFormdataRef.current = [...imageFormdataRef.current,file]
    });
  };

  function deleteImg(ele){
    const updatedImageData = UpdateImagedata && UpdateImagedata.filter((element) => ele !== element);
   setUpdateimagedata(updatedImageData);
    }

    async function compressImage(file) {
      const maxSizeMB = 3;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      if (file.size <= maxSizeBytes) {
        return file;
      }
  
      const initialQuality = 0.7;
      let currentQuality = initialQuality;
  
      while (true) {
        try {
          const compressedFile = await imageCompression(file, { quality: currentQuality });
          if (compressedFile.size <= maxSizeBytes || currentQuality <= 0.1) {
            return compressedFile;
          }
          currentQuality -= 0.1;
        } catch (error) {
          throw error;
        }
      }
    }

    const onFinish = (values) => {
      let sendData = {
        ...(values?.content?.length > 0 ? {content:values?.content} : {}),
        ...(values?.platform?.length > 0 ? {platform:values?.platform} : {}),
        ...(values?.priority?.length > 0 ? {priority:values?.priority} : {}),
        ...(values?.assignedTo?.length > 0 ? {assignedTo:clientID} : {}),
        ...(typeChanged && values?.type?.length > 0 ? {type:values?.type} : {}),
        _id:handleData?.[0]?._id,
      }
      setTypeChanged(false);
      if(input.length > 0){
        sendData = {...sendData,assignedTo:clientID}
      }
          if(renderComp=== "listings"){
              dispatch(bugReportHandle.updateImageforBugNew(sendData,imageFormdataRef.current, "notBase64","BUGS",true,handleData,searchbugdata));
          }else if(renderComp === "suggestions"){
              dispatch(bugReportHandle.updateImageforBugNew(sendData,imageFormdataRef.current, "notBase64","SUGGESTIONS",true,handleData,searchbugdata));
          }else{
              dispatch(bugReportHandle.updateImageforBugNew(sendData,imageFormdataRef.current, "notBase64","SUPPORT",true,handleData,searchbugdata));
          }
          handleCancel("UPDATING");
      };

 var dataArr = [];
      dataArr.push(handleData);

      useEffect(() => {
        for(let key in getUserDetailsData?.roles){
          if(getUserDetailsData?.roles[key] === "RESURE_SUPPORT"){
            setRole(true);
            break;
          }else{
            setRole(false);
          }
        }
      },[getUserDetailsData?.roles])

      useEffect(() => {
        if(userDataPage > 1){
          dispatch(bugReportHandle.getUsersData(userDataPage))
        }
        if (searchUserdata.length > 0) {
          const timeoutId = setTimeout(() => {
            dispatch(bugReportHandle.getUsersData(userDataPage,searchUserdata))
          }, 1000);
          return () => clearTimeout(timeoutId);
        }
      },[userDataPage,searchUserdata])

      useEffect(() => {
        let handlePasteEvent = async (event) => {
          if (isTextPasteRef.current) return;
          let count = 0;
          var items = (event.clipboardData || event.originalEvent.clipboardData)
            .items;
          for (let index in items) {
            var item = items[index];
            if (item.kind === "file" && count === 0) {
              var blob = item.getAsFile();
              var reader = new FileReader();
              const ide = Math.floor(10000 + Math.random() * 90000);
              const imageName = "pastedImage" + ide;
              var updatedBlob = new File([blob], imageName, { type: blob.type });
              const image = await compressImage(updatedBlob)
              let checkImage = beforeUpload(updatedBlob);
              if (checkImage) {
                reader.onload = () => {
                  const newFile = {
                    name: updatedBlob.name,
                    size: bytesToSize(image.size),
                    file: reader.result,
                    origin: "notBase64",
                  };
                  setUpdateimagedata((prevFiles) =>{
  
                    if (prevFiles.length) {
                        return [...prevFiles, newFile];
                      } else {
                        return [newFile];
                      }
                });
                };
                imageFormdataRef.current = [...imageFormdataRef.current,image]
              }
              reader.readAsDataURL(image);
              count = +count + 1;
            }
          }
        };
        let getModal = document;
        getModal.addEventListener("paste", handlePasteEvent);
        return () => {
          getModal.removeEventListener("paste", handlePasteEvent);
        };
      }, []);

  return (
    role || (roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) ?
    <div
      style={{
        width: "350px",
        marginTop:"20px",
        height: "95%",
        background: "#fff",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        padding: "0rem 1rem",
        marginRight: "2px",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >

{modalVisible && <EditBugModal handleCancel={handleCancel} formItemLayout={formItemLayout} onFinish={onFinish} handleQuillPaste={handleQuillPaste} priorityOptions={priorityOptions} typeOptions={typeOptions} handleAddFile={handleAddFile} handleChangeImage={handleChangeImage} deleteImg={deleteImg} handleCustome={handleCustome} beforeUpload={beforeUpload} renderComp={renderComp} modalVisible={modalVisible} spot6={spot6} isMobile={isMobile} form={form} handleData={handleData} quillRef={quillRef} spot8={spot8} spot7={spot7} roleAccessed={roleAccessed} role={role} input={input} usersData={usersData} dispatch={dispatch} setInput={setInput} setSearchUserData={setSearchUserData} userDataPage={userDataPage} setUserModalVisible={setUserModalVisible} setTypeChanged={setTypeChanged} UpdateImagedata={UpdateImagedata} isTab1={isTab1} bugPostLoading={bugPostLoading} />}

        {/* <-------------------------------------------------------------------> */}


      <Modal
        title="My Bug"
        visible={handleModal}
        footer={false}
        onCancel={() => handleModalData(null, false)}
      >
         <List
          itemLayout="vertical"
          size="large"
          dataSource={dataArr}
          renderItem={(item) =>{

            return <List.Item
              key={item.title}
              actions={[
                <Typography.Title
                  level={5}
                  style={{
                    margin: 0,
                  }}
                >
                  Bug Id: {item?._id}
                </Typography.Title>,
                  <DeleteOutlined
        style={{color:"cornflowerblue"}}
          onClick={()=>handleShowDeleteModal(item._id)
         }
      />
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    size={65}
                    style={{
                      background: "#085191",
                      // marginLeft: "40%",
                    }}
                    src={item?.profileImg?.original}
                  >
                    {item &&
                      (item?.createdBy?.firstName?.[0] ? item?.createdBy?.firstName?.[0]?.toUpperCase() : "") +
                        " " +
                      (item?.createdBy?.lastName?.[0] ? item?.createdBy?.lastName?.[0]?.toUpperCase() : "")}
                  </Avatar>
                }
                title={<a href={item.href}>{item.title?item?.title?.toUpperCase():""}</a>}
                description={
                  (item?.createdBy?.firstName ? item.createdBy.firstName : "") + " " + (item?.createdBy?.lastName ? item.createdBy.lastName : "")
                }
              />
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                {item?.content?.map((el,ind)=>(<div style={{display:"flex",flexDirection:"column"}}>
                {el?.description &&
                <>
                {ind !== 0 &&
                <div style={{color:"#2976be"}}>
                   {`${moment(el?.addedDate).format('MMMM D, YYYY hh:mm A')} by ${el?.addedBy?.fullName}`}
                </div>
                }
                <div dangerouslySetInnerHTML={{__html:el?.description}}>
                </div>
                </>
                }
            </div>))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ display: "flex", justifyContent: "center",padding:"20px 10px" }}
                >
                    {item?.imageUrls && item?.imageUrls.map(img=>{
                       return <Image
                        width={200}
                        alt="logo"
                        src={img}
                        />
                    })}
                </Col>
              </Row>
            </List.Item>
          }}
        />

      </Modal>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          background: "white",
          padding: "30px 0px 20px 0px",
          position: "sticky",
          top: "0px",
          zIndex: "1",
        }}
      >
        <Title level={3}>{renderComp==="listings" ? 'Your Bugs' : renderComp === "suggestions" ? "Your Suggestions" : "Your Tickets"}</Title>
      </div>
      <>{myBugLlistingLoading ? <div style={{marginTop:"60%"}}><Loading/></div> :
      <>
      {myBugLlistingLoading ? null : myBugLlistingData?.length > 0 ? (
          <div style={{height:"75vh",overflowX:"hidden",overflowY:"auto"}}
          onScroll={handlescroll}
          >
          <List
          className="infinite-container"
          itemLayout="horizontal"
        //   loadMore={loadMore}
          dataSource={myBugLlistingData}
          renderItem={(item, index) => {
            if (index < clientcount)
              return (
                <List.Item
                  style={{
                    cursor: "pointer",
                    // border: "2px solid red",
                  }}

                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        size={65}
                        style={{
                          background: "#085191",
                          // marginLeft: "40%",
                        }}
                        src={item.createdBy.profileImg.original}
                        onClick={(e) =>{
                            e.stopPropagation();
                            handleModalData(item, true);
                        }}
                      >
                        {item &&
                          (item?.createdBy?.firstName?.[0] ? item.createdBy.firstName[0]?.toUpperCase() : "") +
                            " " +
                          (item?.createdBy?.lastName?.[0] ? item.createdBy.lastName[0]?.toUpperCase() : "")}
                      </Avatar>
                    }
                    title={
                        <span style={{display:"flex",justifyContent:"space-between"}}>
                      <span className={styles.yourBugsTitle}
                      onClick={(e) =>{
                        e.stopPropagation();
                        handleModalData(item, true);
                    }}
                    >
                        {item.title?item?.title?.toUpperCase():""}
                      </span>
                      <span>
                      <Dropdown overlay={<MenuItem item={item} />} trigger={['hover','click']}>
                      <MoreOutlined
                        key="ellipsis"
                        style={{
                          fontSize: "26px",
                          color: "#08c",
                          marginTop: "-30px",
                        }}
                      />
                    </Dropdown>
                      </span>
                      </span>
                    }
                    description={
                     <span style={{cursor:"default"}}>
                      <IconText
                        icon={StarOutlined}
                        text={item.status}
                        key="list-vertical-star-o"
                        item={item}
                        handle={null}
                      />
                      </span>
                    }
                  />
                </List.Item>
              );
          }}
        />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "140px",
          }}
        >
          <EmptyData />
        </div>
      )}
      </> }
      {/* User Modal */}
      <Modal
        title={<h2 style={{color:"grey",textAlign:"center"}}>Users</h2>}
        visible={userModalVisible}
        onOk={() => {}}
        onCancel={handleUserModalCancel}
        footer={false}
        style={{marginTop: "-50px",overflowY: "auto",
        overflowX: "hidden",height:"90%"}}
      >
       <div>
        {usersDataLoading ? (
          <div style={{ marginTop: "50%" }}>
            <Loading />
          </div>
        ) : (
          <>
          <Input.Search placeholder='Search user' value={searchUserdata} onChange={(e) => setSearchUserData(e.target.value)} allowClear/>
          <List
            bordered={true}
            itemLayout="horizontal"
            dataSource={usersData}
            renderItem={(item) => {
              return (
                <List.Item
                  className={styles.clientListItem}
                  key={item._id}
                >
                  <List.Item.Meta
                    onClick={() => {
                      setClientID(item?._id)
                      setInput(item?.fullName)
                      setUserModalVisible(false)
                    }}
                    title={
                      <span style={{ fontSize: "20px" }}>
                        {(item?.fullName ? capitalizeStr(item?.fullName) : "")}
                      </span>
                    }
                    description={
                      item?.email ? (
                        <Text ellipsis>{item.email}</Text>
                      ) : ""
                    }
                  />
                </List.Item>
              );
            }}
          />
          <Pagination
            current={userDataPage}
            total={totalUsersRecords}
            pageSize={15}
            onChange={(page) => setUserDataPage(page)}
            style={{ marginTop: '20px', textAlign: 'center' }}
            pageSizeOptions={[15]}
          />
        </>
        )}
      </div>
      </Modal>
      </>
    </div> : null
  );
}

export { SideBarRight };
