import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Typography, List, Checkbox } from "antd";
import { clientAction } from "../state/actions";
import { useRef } from "react";
import { capitalizeStr} from "../../Common/utils/extraFunctions";
import { useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UserSwitchOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";

function NickNameDetails({ data, onFinish, setModelDrawer, selectedClient }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(data.length === 0);

  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);

  const handleEdit = (name) => {
    setEditingItem(name);

    dispatch(clientAction.resetAddNewRequest());
    form.setFieldsValue({
      nickName: name?.name,
      isDefault: name?.isPrimary,
    });
    setIsFormOpen(true);
  };

  useEffect(() => {
    if (isFormOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFormOpen]);

  useEffect(() => {
     if (data.length === 0) {
      form.setFieldsValue({ isPrimary: true });
       return;
     } else {
       form?.resetFields();
       setEditingItem(null);
     }
   }, [isAddNew]);
 
   
   useEffect(() => {
     if (data.length === 0) {
      form.setFieldsValue({ isPrimary: true });
       setIsFormOpen(true);
       dispatch(clientAction.addNewRequest())
     }
   }, [data,form]);
  const handleNickNameSubmit = (values) => {
    const isPrimary = values.isPrimary || false; // Ensure it's always true/false

    let updatedNickNames = data?.map((item) => {
      if (item === editingItem) {
        return {
          ...item,
          name: values.nickName,
          isDefault: editingItem.isDefault ? true : isPrimary, // Keep default if already true
        };
      }
      return isPrimary ? { ...item, isDefault: false } : item; // Remove previous default if new one is primary
    });

    if (!editingItem) {
      // If adding a new nickname
      updatedNickNames = [
        ...updatedNickNames.map((item) => ({
          ...item,
          isDefault: isPrimary ? false : item.isDefault, // Remove previous default if new is primary
        })),
        {
          name: values?.nickName,
          isDefault: isPrimary,
        },
      ];
    }

    // Prepare final object
    let updatedNickData = {
      nicknames: updatedNickNames,
    };

    // Add nickName only if isPrimary is true
    if (isPrimary) {
      updatedNickData = {
        ...updatedNickData,
        name: values.nickName,
      };
    }

    // Pass updated nickname data to parent
    onFinish(updatedNickData);

    form.resetFields();
    setIsFormOpen(false);
    setEditingItem(null);
    dispatch(clientAction.resetAddNewRequest());
  };
  const handleDelete = (nicknameToDelete) => {
    let updatedNicknames = data?.filter((item) => item._id !== nicknameToDelete._id); // Remove the deleted nickname

    let newPrimaryNickname = null;

    if (nicknameToDelete.isDefault && updatedNicknames.length > 0) {
      // If the deleted nickname was default, assign the next available one as default
      updatedNicknames[0] = { ...updatedNicknames[0], isDefault: true };
      newPrimaryNickname = updatedNicknames[0]; // Capture the new default nickname
    }

    // Prepare the updated data payload
    let updatedData = { nicknames: updatedNicknames };

    if (newPrimaryNickname) {
      updatedData = {
        ...updatedData,
        defaultNickname: newPrimaryNickname.nickname,
      };
    }
    dispatch(clientAction.deleteNickName(updatedData, selectedClient));
    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
  };
  const showDeleteConfirm = (nameToDelete) => {
    confirm({
      title: "Are you sure you want to Remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(nameToDelete);
      },
    });
  };
  return (
    <>
      {!(isFormOpen || isAddNew) && (
        <div style={{ maxHeight: "200px", overflowY: "auto", width: "100%", padding: "0px", marginTop: "-20px" }}>
          <List
            bordered={false}
            size="default"
            dataSource={[...data].sort((a, b) => (b.isDefault ? 1 : -1))}
            renderItem={(item) => (
              <List.Item style={{ cursor: "pointer" }}>
                <Typography.Text strong style={{ fontSize: "18px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <UserSwitchOutlined style={{ marginRight: "10px", marginTop: "5px" }} />
                    </div>
                    <div>
                      {capitalizeStr(item?.name)}
                      {item?.isDefault && <span style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>(Default)</span>}
                    </div>
                  </div>
                </Typography.Text>
                <div style={{ display: "flex" }}>
                  <EditOutlined onClick={() => handleEdit(item)} />
                  {data?.length > 1 && <DeleteOutlined style={{ marginLeft: "10px" }} onClick={() => showDeleteConfirm(item)} />}
                </div>
              </List.Item>
            )}
          />
        </div>
      )}
      {!isAddNew && editingItem && (
        <div>
          <Typography.Text strong style={{ fontSize: "18px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <UserSwitchOutlined style={{ marginRight: "10px", marginTop: "5px" }} />
              </div>
              <div>
                {capitalizeStr(editingItem?.name)}
                {editingItem?.isDefault && <span style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>(Default)</span>}
              </div>
            </div>
          </Typography.Text>
        </div>
      )}
      {(isFormOpen || isAddNew) && (
        <div style={{ position: "sticky", bottom: 0, background: "white", zIndex: 10, padding: "10px 0" }}>
          <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", color: "grey", marginBottom: "20px" }}>{isAddNew || data.length === 0 ? "Add" : "Edit"} Nickname</span>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            initialValues={
              isAddNew
                ? {}
                : {
                    name: data?.name,

                    isPrimary: editingItem?.isDefault || false,
                  }
            }
            onFinish={handleNickNameSubmit}
            autoComplete="off"
          >
            <Form.Item label="Nickname" name="nickName" rules={[{ required: true, message: "Please Enter Nickname!" }]}>
              <Input ref={inputRef} placeholder="Enter Nickname" allowClear  />
            </Form.Item>

            {!editingItem?.isDefault && (
              <Form.Item name="isPrimary" valuePropName="checked" disabled={data.length === 0} wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox disabled={data.length === 0 || editingItem?.isDefault}>Make this as primary Nickname</Checkbox>
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                <Button
                  onClick={() => {
                    setIsFormOpen(false);
                    setEditingItem(null);
                    form.resetFields()
                    dispatch(clientAction.resetAddNewRequest());
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}
export { NickNameDetails };
