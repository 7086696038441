import { Button, Modal, Tooltip, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientAction } from "../state/actions";

const SaveCancelModal = ({ modelDrawer, setModelDrawer, setIsAddNew,name }) => {
  const Text = Typography;
  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);
  const dispatch = useDispatch();
  return (
    <Modal
      open={modelDrawer?.status}
      title={
        <div className="commonModalTitle">
          {modelDrawer?.title}
          {modelDrawer?.toolTip && modelDrawer?.title !== "Address Details" && modelDrawer?.title !== "Relationships Details" && modelDrawer?.title !== "EVENT DETAILS" && modelDrawer?.source !== "transaction" && (
            <Tooltip title={modelDrawer?.toolTip} trigger={["hover", "click"]}>
              {!isAddNew && (
                <Button
                  type="primary"
                  style={{
                    position: "absolute",
                    right: "40px",
                    color: "white",
                  }}
                  onClick={() => dispatch(clientAction.addNewRequest())}
                >
                  Add
                </Button>
              )}
            </Tooltip>
          )}
        </div>
        // <Text style={{ color: "#1990ff", display: "flex", justifyContent: "center", fontSize:"22px", fontWeight: "bold" }}>
        //   {modelDrawer.title}
        // </Text>
      }
      okText="Save"
      cancelText="Cancel"
      footer={false}
      onCancel={() => {
        setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
        dispatch(clientAction.resetAddNewRequest());
      }}
    >
      {modelDrawer?.component}
    </Modal>
  );
};

export { SaveCancelModal };
