import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, message, Modal, Input } from "antd";
import { changeExtensiontoLowerCase } from "../utils/extraFunctions";

const DraggerContactCsvFile = ({ uploadFile, uploadTemp, handleCancel, setImportFromCsvFile }) => {
  const [fileText, setFileText] = useState({ name: null, size: null });
  const [showInputFileName, setShowInputFileName] = useState(false);
  const [fileNametext, setFileNametext] = useState("");
  const [fileSizeOfDocUpload, setFileSizeOfDocUpload] = useState("");
  const [loading, setLoading] = useState(false);

  function beforeUpload(file) {
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      message.error("Only CSV files are allowed.");
      return false;
    }
    return true;
  }

  const handleCustom = (event) => {
    const file = event?.file;
    if (!file) return;

    setLoading(true);
    
    const fileName = changeExtensiontoLowerCase(file.name);
    const fileSize = bytesToSize(file.size);

    setFileText({ name: fileName, size: fileSize });
    setFileNametext(fileName);
    setFileSizeOfDocUpload(fileSize);

    const formData = new FormData();
    formData.append("file", file);
    // formData.append("fileType", "CSV");

    setShowInputFileName(true);
    uploadFile.current = formData;

    setLoading(false);
  };

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const handleCancelInputFileName = () => {
    if (handleCancel) handleCancel();
    setShowInputFileName(false);
    setFileText({});
    setFileNametext("");
    setImportFromCsvFile(false);
  };

  const handleUploadFunc = () => {
    uploadTemp(setShowInputFileName, fileNametext, setFileNametext);
  };

  return (
    <>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <Dragger
          progress={false}
          style={{ width: "400px" }}
          accept=".csv"
          showUploadList={false}
          customRequest={handleCustom}
          beforeUpload={beforeUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag a CSV file to this area to upload</p>
          <p className="ant-upload-hint">Only CSV files are allowed.</p>
        </Dragger>
      </div>

      <Modal
        title="Enter File Name"
        open={showInputFileName}
        onCancel={handleCancelInputFileName}
        footer={
          <Button type="primary" onClick={handleUploadFunc} disabled={!fileNametext}>
            Upload
          </Button>
        }
      >
        <p><strong>File Name:</strong> {fileNametext}</p>
        <p><strong>File Size:</strong> {fileSizeOfDocUpload}</p>
        <Input
          value={fileNametext}
          placeholder="Enter File Name"
          onChange={(e) => setFileNametext(e.target.value)}
        />
      </Modal>
    </>
  );
};

export { DraggerContactCsvFile };
