import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Typography, List, Checkbox } from "antd";
import { clientAction } from "../state/actions";
import { useRef } from "react";
import { capitalizeStr } from "../../Common/utils/extraFunctions";
import { useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { Loading } from "../../Common/components/Loading";
import { BiUser } from "react-icons/bi";

function NameDetails({ data, onFinish, setModelDrawer, selectedClient,onDeleteName }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const [isFormOpen, setIsFormOpen] = useState(data.length === 0);
  const [editingItem, setEditingItem] = useState(null);

  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);

  const handleEdit = (name) => {
    setEditingItem(name);
    dispatch(clientAction.resetAddNewRequest());
    form.setFieldsValue({
      firstName: name.firstName,
      middleName: name.middleName || "",
      lastName: name.lastName,
      isPrimary: name?.isDefault,
    });

    setIsFormOpen(true);
  };
  const showDeleteConfirm = (nameToDelete) => {
    confirm({
      title: "Are you sure you want to Remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(nameToDelete);
      },
    });
  };

  const handleDelete = (nameToDelete) => {
    let updatedNames = data.filter((item) => item._id !== nameToDelete._id); // Remove the deleted item

    let newPrimary = null;

    if (nameToDelete.isDefault && updatedNames.length > 0) {
      updatedNames[0] = { ...updatedNames[0], isDefault: true };
      newPrimary = updatedNames[0];
    }

    let updatedData = { names: updatedNames };

    if (newPrimary) {
      updatedData = {
        ...updatedData,
        firstName: newPrimary.firstName,
        middleName: newPrimary.middleName || "",
        lastName: newPrimary.lastName,
      };
    }

    onDeleteName(updatedData);
    
  };

  useEffect(() => {
    if (isFormOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFormOpen]);

  useEffect(() => {
    form?.resetFields();
    setEditingItem(null);
  }, [isAddNew]);
  
  useEffect(() => {
    if (data.length === 0) {
      setIsFormOpen(true);
      dispatch(clientAction.addNewRequest())
    }
  }, [data]);

  const handleFormSubmit = (values) => {
    const isPrimary = values.isPrimary || false; // Ensure it's always true/false

    // Update existing names array
    let updatedNames = data?.map((item) => {
      if (item === editingItem) {
        return {
          ...item,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          fullName: `${values.firstName} ${values.lastName}`,
          isDefault: editingItem.isDefault ? true : isPrimary,
        };
      }

      return isPrimary ? { ...item, isDefault: false } : item;
    });

    if (!editingItem) {
      updatedNames = [
        ...updatedNames, // Keep the existing names
        {
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          fullName: `${values.firstName} ${values.lastName}`,
          isDefault: isPrimary, // Set primary if selected
        },
      ];
    }

    let updatedData = {
      names: updatedNames,
    };

    if (isPrimary || editingItem?.isDefault) {
      updatedData = {
        ...updatedData,
        firstName: values.firstName,
        middleName: values.middleName || "",
        lastName: values.lastName,
      };
    }

    // Pass updated names array to the parent component
    onFinish(updatedData);

    // Reset form and close edit view
    form.resetFields();
    setIsFormOpen(false);
    setEditingItem(null);
    dispatch(clientAction?.resetAddNewRequest());
  };

  return (
    <>
      {!(isFormOpen || isAddNew) && (
        <div style={{ maxHeight: "200px", overflowY: "auto", width: "100%", padding: "0px", marginTop: "-20px" }}>
          <List
            bordered={false}
            size="default"
            dataSource={[...data].sort((a, b) => (b.isDefault ? 1 : -1))}
            renderItem={(item) => (
              <List.Item style={{ cursor: "pointer" }}>
                <Typography.Text strong style={{ fontSize: "18px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <BiUser style={{ marginRight: "10px",marginTop:"10px" }} />
                    </div>
                    <div>
                      {(item.firstName ? capitalizeStr(item.firstName) : "") + " " + (item.middleName && item?.middleName !== "undefined" && item?.middleName !== "null" ? capitalizeStr(item?.middleName) : "") + " " + (item.lastName ? capitalizeStr(item.lastName) : "")}
                      <text style={{ fontSize: "14px", color: "grey" }}> {item?.isDefault ? " (Default)" : ""}</text>
                    </div>
                  </div>
                </Typography.Text>
                <div style={{ display: "flex" }}>
                  <EditOutlined onClick={() => handleEdit(item)} />
                  {data?.length > 1 && <DeleteOutlined style={{ marginLeft: "10px" }} onClick={() => showDeleteConfirm(item)} />}
                </div>
              </List.Item>
            )}
          />
        </div>
      )}
      {!isAddNew  && editingItem && (
        <Typography.Text strong style={{ fontSize: "18px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <BiUser style={{ marginRight: "10px",marginTop:"10px" }} />
            </div>
            <div>
              {(editingItem.firstName ? capitalizeStr(editingItem.firstName) : "") + " " + (editingItem.middleName && editingItem.middleName !== "undefined" && editingItem.middleName !== "null" ? capitalizeStr(editingItem.middleName) : "") + " " + (editingItem.lastName ? capitalizeStr(editingItem.lastName) : "")}
              {editingItem?.isDefault && <span style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>(Default)</span>}
            </div>
          </div>
        </Typography.Text>
      )}
      {(isFormOpen || isAddNew) && (
        <div style={{ position: "sticky", bottom: 0, background: "white", zIndex: 10, padding: "10px 0" }}>
          <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", color: "grey", marginBottom: "20px" }}>{isAddNew || data.length === 0 ? "Add" : "Edit"} Name Details</span>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            initialValues={
              isAddNew
                ? {}
                : {
                    firstName: data?.firstName,
                    middleName: data?.middleName && data?.middleName ? data?.middleName : "",
                    lastName: data?.lastName ? data?.lastName : "",
                    isPrimary: editingItem?.isDefault || false,
                  }
            }
            onFinish={handleFormSubmit}
            autoComplete="off"
          >
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: "Please Enter First Name!" }]}>
              <Input ref={inputRef} placeholder="Enter First Name" allowClear  />
            </Form.Item>
            <Form.Item label="Middle Name" name="middleName">
              <Input placeholder="Enter Middle Name" allowClear  />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please Enter Last Name!" }]}>
              <Input placeholder="Enter Last Name" allowClear  />
            </Form.Item>
            {!editingItem?.isDefault && (
              <Form.Item name="isPrimary" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox disabled={editingItem?.isDefault}>Make this as primary name</Checkbox>
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ span: 24 }}>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button
              onClick={() => {
                setIsFormOpen(false);
                setEditingItem(null);
                form.resetFields()
                dispatch(clientAction.resetAddNewRequest());
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" >
              Save
            </Button>
            
          </div>
        </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}
export { NameDetails };
