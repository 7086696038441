import { bugListingPageConstants } from "./action-types";
const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    href: "https://ant.design",
    title: `Realtor ${i}`,
    avatar: "https://joeschmoe.io/api/v1/random",
    description:
      "Section 1.10.32 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  });
}

const initialState = {
  loggedIn: false,
  listData: listData,

  bugLlistingData: [],
  bugpagedata:[],
  bugLlistLoading: true,
  bugLlistingError: null,

  suggestionsData: [],
  suggestionsDataLoading: false,
  suggestionsDataError: false,

  supportData: [],
  supportDataLoading: false,
  supportDataError: false,

  bugLlistingWithoutData: [],
  bugLlistWithoutLoading: true,
  bugLlistingWithoutError: null,

  bugPostData: null,
  bugPostLoading: false,
  bugPostError: null,

  uploadImageData: [],
  uploadImageLoading: false,
  uploadImageError: null,

  deleteContractData: null,
  deleteContractLoading: false,
  deleteContractError: null,

  logInData: null,
  logInLoading: true,
  logInError: null,

  myBugLlistingData: [],
  myBugLlistingLoading: false,
  myBugLlistingError: null,

  bugLikeData: null,
  bugLikeLoading: true,
  bugLikeError: null,

  bugCommentsData: [],
  bugCommentsLoading: false,
  bugCommentsError: false,

  renderCompLoading: false,
  renderComp:"listings",
  renderCompError: false,

  searchbugdata:[],

  filteredData : [],
  filteredDataLoading : false,
  filteredDataError : false,
  status : "ACTIVE",
  like : null,
  priority : "",
  startDate : "",
  endDate : "",

  usersData : [],
  usersDataLoading : false,
  usersDataError : false,
};

const bugReport = (state = initialState, action) => {
    switch (action.type) {
        //like functionality
        case bugListingPageConstants.BUG_LIKE__SUCCESS: {
            return {
                ...state,
        bugLikeData: action.bugLikeData,
        bugLikeLoading: action.bugLikeLoading,
      };
    }
    case bugListingPageConstants.BUG_LIKE_FAILURE:
      return {
        ...state,
        bugLikeData: null,
        bugLikeLoading: action.bugLikeLoading,
        bugLikeError: action.bugLikeError,
      };
      case bugListingPageConstants.SEARCH_BUG:{
        return {
          ...state,
          myBugLlistingLoading: false,
          bugLlistLoading: false,
          searchbugdata:action.bugSearchData.data,
        };

      }
    //may bugs
    case bugListingPageConstants.MY_BUG_LISTING_PAGE__LOADING: {
      return {
        ...state,
        myBugLlistingLoading: true,
        myBugLlistingError: false,
      };
    }
    case bugListingPageConstants.MY_BUG_LISTING_PAGE__SUCCESS: {
      return {
        ...state,
        myBugLlistingData: action.myBugLlistingData,
        myBugLlistingLoading: false,
        myBugLlistingError: false,
      };
    }
    case bugListingPageConstants.MY_BUG_LISTING_PAGE_FAILURE:
      return {
        ...state,
        myBugLlistingData: null,
        myBugLlistingLoading: false,
        myBugLlistingError: action.myBugLlistingError,
      };
    // with access token
    case bugListingPageConstants.BUGS_LOGIN_UPDATE_PAGE: {
      localStorage.setItem("userAuth", action.logInData.accessToken);

      return {
        ...state,
        logInData: action.logInData,
        logInLoading: action.logInLoading,
      };
    }
    case bugListingPageConstants.BUGS_LOGIN_UPDATE_PAGE_FAIL:
      return {
        ...state,
        logInData: null,
        logInLoading: action.logInLoading,
        logInError: action.logInError,
      };

    // with access token
    case bugListingPageConstants.BUG_LISTING_PAGE_LOADING:
      return {
        ...state,
        bugLlistLoading: true,
        bugLlistingError: false,
      };
    case bugListingPageConstants.BUG_LISTING_PAGE_SUCCESS:
     let updatedBugPagedata;
     if(action.pagination){
        updatedBugPagedata = [...state.bugpagedata, ...action.bugLlistingData.data];
      } else {
        updatedBugPagedata = [...action.bugLlistingData.data, ...state.bugpagedata];
      }
      return {
        ...state,
        bugLlistLoading: action.myBugLlistingLoading,
        bugLlistingData: action.bugLlistingData.data,
        bugpagedata:updatedBugPagedata,
        totalrecords:action.bugLlistingData.totalRecords,
        bugLlistingError: null,
      };
    case bugListingPageConstants.BUG_LISTING_PAGE_FAILURE:
      return {
        ...state,
        bugLlistingData:[],
        bugLlistLoading: false,
        bugLlistingError: action.bugLlistingError,
      };

    // without access token
    case bugListingPageConstants.BUG_LISTING_PAGE_WITHOUT_SUCCESS:
      return {
        ...state,
        bugLlistingWithoutData: action.bugLlistingWithoutData,
        bugLlistWithoutLoading: action.bugLlistWithoutLoading,
        bugLlistingWithoutError: null,
      };
    case bugListingPageConstants.BUG_LISTING_PAGE_WITHOUT_FAILURE:
      return {
        ...state,
        bugLlistingWithoutData: [],
        bugLlistWithoutLoading: action.bugLlistWithoutLoading,
        bugLlistingWithoutError: action.bugLlistingWithoutError,
      };

      case bugListingPageConstants.POST_BUG_LOADING:
      return {
        ...state,
        bugPostData: action.bugPostData,
        bugPostLoading: true,
        bugPostError: null,
        // uploadImageData: null,
      };
    case bugListingPageConstants.POST_BUG_SUCCESS:
      return {
        ...state,
        bugPostData: action.bugPostData,
        bugPostLoading: action.bugPostLoading,
        bugPostError: null,
        // uploadImageData: null,
      };
    case bugListingPageConstants.POST_BUG_FAILURE:
      return {
        ...state,
        bugPostData: [],

        bugPostLoading: action.bugPostLoading,
        bugPostError: action.bugPostError,
      };

    case bugListingPageConstants.UPLOAD_IMAGE_SUCCESS:
     return {
        ...state,
        uploadImageData: [...state.uploadImageData,action.uploadImageData],
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: null,
      };
    case bugListingPageConstants.UPLOAD_IMAGE_LOADING:
      return {
        ...state,
        uploadImageLoading : true,
        uploadImageError : false,
      }
    case bugListingPageConstants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        // uploadImageData: [],
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: action.uploadImageError,
      };

    case bugListingPageConstants.LOGIN_UPDATE_PAGE:
      return {
        ...state,
        loggedIn: true,
      };
    case bugListingPageConstants.LOGOUT_PAGE: {
      localStorage.removeItem("userAuth");
      window.location.replace("/bugsReport");
      return {
        ...state,
        logInData: null,
      };
    }

    case bugListingPageConstants.RESET_LISTING_PAGE:
      return {
        ...initialState,
      };
    case bugListingPageConstants.RESET_IMAGE_UPLOAD_PAGE: {
      return {
        ...state,
        //       uploadImageData: null,
        // uploadImageLoading: false,
        // uploadImageError: null,
      };
    }

    // Bug comments
    case bugListingPageConstants.GET_BUG_COMMENTS_LOADING:{
        return {
            ...state,
            bugCommentsLoading:true,
            bugCommentsError:false,
        }
    }

    case bugListingPageConstants.GET_BUG_COMMENTS_SUCCESS:{
        return {
            ...state,
            bugCommentsData:[...state.bugCommentsData, action.payload],
            bugCommentsLoading:false,
            bugCommentsError:false,
        }
    }

    case bugListingPageConstants.GET_BUG_COMMENTS_ERROR:{
      return {
        ...state,
        bugCommentsLoading:false,
        bugCommentsError:true,
      }
    }


    case bugListingPageConstants.RESET_BUG: {
      return {
        ...state,
        bugPostData: null,
      };
    }

    case bugListingPageConstants.RESET_SEARCH: {
        return {
          ...state,
          searchbugdata: [],
        };
      }

    //render specific componant
    case bugListingPageConstants.RENDER_COMPONANT_LOADING:{
        return {
            ...state,
            renderCompLoading:true,
            renderCompError:false,
        }
    }

    case bugListingPageConstants.RENDER_COMPONANT_SUCESS:{
        return {
            ...state,
            renderCompLoading:true,
            renderCompError:false,
            renderComp:action.data,
        }
    }

    case bugListingPageConstants.RENDER_COMPONANT_FAILURE: {
      return {
        ...state,
        renderCompError: false,
      };
    }

    //suggestions
    case bugListingPageConstants.SUGGESTIONS_DATA_LOADING: {
        return {
          ...state,
          suggestionsDataLoading: true,
          suggestionsDataError: false,
        };
      }
      case bugListingPageConstants.SUGGESTIONS_DATA_SUCCESS:
        let updatedSuggestionPagedata;
        if(action.pagination){
          updatedSuggestionPagedata = [...state.suggestionsData, ...action.bugSuggestionData.data];
          } else {
            updatedSuggestionPagedata = [...action.bugSuggestionData.data, ...state.suggestionsData];
          }
          return {
            ...state,
            suggestionsDataLoading: false,
            suggestionsData:updatedSuggestionPagedata,
            suggestionRecords:action.bugSuggestionData.totalRecords,
            suggestionsDataError: false,
          };
        // return {
        //   ...state,
        //   suggestionsData: action.bugSuggestionData.data,
        //   suggestionsDataLoading: false,
        //   suggestionsDataError: false,
        // };

    //support
    case bugListingPageConstants.SUPPORT_DATA_LOADING: {
      return {
        ...state,
        supportDataLoading: true,
        supportDataError: false,
      };
    }
    case bugListingPageConstants.SUPPORT_DATA_SUCCESS:
      let updatedSupportPagedata;
     if(action.pagination){
        updatedSupportPagedata = [...state.supportData, ...action.bugSupportData.data];
      } else {
        updatedSupportPagedata = [...action.bugSupportData.data, ...state.supportData];
      }
      return {
        ...state,
        supportDataLoading: false,
        supportData:updatedSupportPagedata,
        supportRecords:action.bugSupportData.totalRecords,
        supportDataError: false,
      };

      // Filtering
      case bugListingPageConstants.FILTERED_DATA_LOADING:{
        return {
          ...state,
          filteredDataLoading:true,
          filteredData:[],
          filteredDataError:false,
        }
      }
      case bugListingPageConstants.FILTERED_DATA_ERROR:{
        return {
          ...state,
          filteredDataLoading:false,
          filteredData:[],
          filteredDataError:true,
        }
      }
      case bugListingPageConstants.FILTERED_DATA_SUCCESS:{
        return {
          ...state,
          filteredDataLoading:false,
          filteredDataError:false,
          filteredData:action.filterData.data
        }
      }
      case bugListingPageConstants.RESET_FILTERED_DATA:{
        return {
          ...state,
          filteredData : [],
          status : "ACTIVE",
          like : null,
          priority : "",
          startDate : "",
          endDate : "",
        }
      }
      case bugListingPageConstants.CHANGE_FILTER_STATUS:{
        return {
          ...state,
          status:action.status,
        }
      }
      case bugListingPageConstants.CHANGE_FILTER_LIKE:{
        return {
          ...state,
          like:action.like,
        }
      }
      case bugListingPageConstants.CHANGE_FILTER_PRIORITY:{
        return {
          ...state,
          priority:action.priority,
        }
      }
      case bugListingPageConstants.CHANGE_FILTER_START_DATE:{
        return {
          ...state,
          startDate:action.startDate,
        }
      }
      case bugListingPageConstants.CHANGE_FILTER_END_DATE:{
        return {
          ...state,
          endDate:action.endDate,
        }
      }

      // Users Data
      case bugListingPageConstants.GET_USERS_DATA_LOADING:{
        return {
          ...state,
          usersDataLoading:true,
        }
      }
      case bugListingPageConstants.GET_USERS_DATA_ERROR:{
        return {
          ...state,
          usersDataLoading:false,
          usersDataError:true,
        }
      }
      case bugListingPageConstants.GET_USERS_DATA_SUCCESS:{
        return {
          ...state,
          usersDataLoading:false,
          usersDataError:false,
          usersData:action.usersData.data,
          totalUsersRecords : action.usersData.totalRecords,
        }
      }

      case bugListingPageConstants.RESET_ALL_DATA:{
        if(action.tab === "BUGS"){
          return {
            ...state,
            bugpagedata : [],
            bugLlistingData : []
          }
        }else if(action.tab === "SUPPORT"){
          return {
            ...state,
            supportData : []
          }
        }else{
          return {
            ...state,
            suggestionsData : []
          }
        }
      }

     case bugListingPageConstants.RESET_CMNTS_DATA:
        let newData = state.bugCommentsData.map(el=>(el.id===action.id)? el.data={}:el);
      return {
        ...state,
        bugCommentsData:newData
      };

    default:
      return state;
  }
};
export { bugReport };
