import React from "react";
import { Modal, Button, Tooltip, message, Spin, Alert } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const ShareLinkModal = ({ title, shareLinkModalOpen, shareLinkModalCancel, shareableUrl, shareLinkModalLoading, source, selectedClient }) => {
  return (
    <Modal
      title={<div className="commonModalTitle">{title}</div>}
      width={600}
      open={shareLinkModalOpen}
      onCancel={shareLinkModalCancel}
      footer={
        !shareLinkModalLoading && [
          <div style={{ display: "flex", justifyContent: "center" }} key="footer-buttons">
            <Tooltip title="Copy Link">
              <Button
                style={{ width: "80px", height: "40px" }}
                onClick={() => {
                  message.success("Link Copied Successfully!");
                  navigator.clipboard.writeText(shareableUrl);
                }}
              >
                <CopyOutlined style={{ fontSize: "28px" }} />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Email">
              <Button
                style={{ width: "80px", height: "40px", marginLeft: "5px" }}
                onClick={() => {
                  const subject = encodeURIComponent("Check this out");
                  const body = encodeURIComponent(shareableUrl);
                  const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
                  const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${body}`;
                  let fallbackTriggered = false;
                  window.location.href = mailtoLink;
                  // Set a fallback timeout
                  const fallbackTimeout = setTimeout(() => {
                    if (!fallbackTriggered) {
                      fallbackTriggered = true;
                      window.open(gmailUrl, "_blank");
                    }
                  }, 2000); 
                  // If user switches away (meaning defailt mail already opened), canceling the fallback
                  const handleVisibilityChange = () => {
                    if (document.visibilityState === "hidden") {
                      fallbackTriggered = true;
                      clearTimeout(fallbackTimeout);
                      document.removeEventListener("visibilitychange", handleVisibilityChange);
                    }
                  };
                  document.addEventListener("visibilitychange", handleVisibilityChange);
                }}
              >
                <MailOutlined style={{ fontSize: "28px", color: "" }} />
              </Button>
            </Tooltip> */}
          </div>,
        ]
      }
    >
      <>
        {shareLinkModalLoading ? (
          <div style={{ minHeight: "20vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin size="medium" />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            {source === "CLIENT_PORTAL" ? (
              <div style={{ width: "100%", marginBottom: "15px", textAlign: "center" }}>
                <Alert style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "auto" }} message={`This link is only for ${selectedClient?.fullName || "the selected client"}. Please do not share it with anyone else.`} type="info" showIcon />
              </div>
            ) : (
              ""
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip title="Copy Link">
                <input
                  value={shareableUrl}
                  className="newNumberInput"
                  readOnly
                  onClick={() => {
                    message.success("Link Copied Successfully!");
                    navigator.clipboard.writeText(shareableUrl);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default ShareLinkModal;
