import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Typography, List, Checkbox, message } from "antd";
import { clientAction } from "../state/actions";
import confirm from "antd/lib/modal/confirm";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { FaMedal } from "react-icons/fa";
import { capitalizeStr } from "../../Common/utils/extraFunctions";

function TitleDetails({ data, selectedClient, setModelDrawer }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const [isFormOpen, setIsFormOpen] = useState(data.length === 0);
  const [editingItem, setEditingItem] = useState(null);
  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);

  const handleEdit = (item) => {
    setEditingItem(item);
    dispatch(clientAction.resetAddNewRequest());
    form.setFieldsValue({
      designation: item?.name || "",
      isDefault: item?.isPrimary || false,
    });
    setIsFormOpen(true);
  };
  const handleFormSubmit = (values) => {
    const isPrimary = values.isPrimary || false;

    let updatedDesignations = data?.map((item) => {
      if (item === editingItem) {
        return {
          ...item,
          name: values.designation,
          isDefault: editingItem.isDefault ? true : isPrimary,
        };
      }

      return isPrimary ? { ...item, isDefault: false } : item;
    });

    if (!editingItem) {
      updatedDesignations = [
        ...updatedDesignations,
        {
          name: values.designation,
          isDefault: values?.isPrimary,
        },
      ];
    }
    let updatedData = {
      designations: updatedDesignations,
    };

    if (isPrimary || editingItem?.isDefault) {
      updatedData = {
        ...updatedData,
        designation: values?.designation,
      };
    }

    dispatch(clientAction.updateTitle(updatedData, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });

    form.resetFields();
    setIsFormOpen(false);
    setEditingItem(null);
    dispatch(clientAction?.resetAddNewRequest());
  };
  const showDeleteConfirm = (designationToDelete) => {
    confirm({
      title: "Are you sure you want to Remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(designationToDelete);
      },
    });
  };

  const handleDelete = (designationToDelete) => {
    let updatedDesignations = data.filter((item) => item._id !== designationToDelete._id); // Remove the deleted item

    let newPrimary = null;

    if (designationToDelete.isDefault && updatedDesignations.length > 0) {
      updatedDesignations[0] = { ...updatedDesignations[0], isDefault: true };
      newPrimary = updatedDesignations[0];
    }

    let updatedData = { designations: updatedDesignations };

    if (newPrimary) {
      updatedData = {
        ...updatedData,
        designation: newPrimary.name,
      };
    }

    dispatch(clientAction.deleteTitle(updatedData, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      form.setFieldsValue({ isPrimary: true });
      return;
    } else {
      form?.resetFields();
      setEditingItem(null);
    }
  }, [isAddNew]);

  
  useEffect(() => {
    if (data.length === 0) {
     form.setFieldsValue({ isPrimary: true });
      setIsFormOpen(true);
      dispatch(clientAction.addNewRequest())
    }
  }, [data,form]);
  const renderForm = () => {
    return (
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={
          isAddNew
            ? {}
            : {
                designation: data?.designation,
                isPrimary: editingItem?.isDefault || false,
              }
        }
        onFinish={handleFormSubmit}
        autoComplete="off"
      >
        <Form.Item label="Title" name="designation" rules={[{ required: true, message: "Please Enter Title!" }]}>
          <Input ref={inputRef} placeholder="Enter Title" allowClear autoFocus />
        </Form.Item>
        {!editingItem?.isDefault && (
          <Form.Item name="isPrimary" valuePropName="checked" disabled={data.length === 0} wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox disabled={data.length === 0 || editingItem?.isDefault}>Make this as primary title</Checkbox>
          </Form.Item>
        )}
        
        <Form.Item wrapperCol={{ span: 24 }}>
                  <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                  <Button
                      onClick={() => {
                        setIsFormOpen(false);
                        setEditingItem(null);
                        form.resetFields()
                        dispatch(clientAction.resetAddNewRequest());
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" >
                      Save
                    </Button>
                    
                  </div>
                </Form.Item>
      </Form>
    );
  };

  return (
    <>
      {!(isFormOpen || isAddNew) && (
        <List
          bordered={false}
          size={"default"}
          dataSource={[...data].sort((a, b) => (b.isDefault ? 1 : -1))}
          renderItem={(item) => (
            <List.Item style={{ cursor: "pointer" }}>
              <Typography.Text strong style={{ fontSize: "18px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <FaMedal style={{ marginRight: "10px",marginTop:"5px" }} />
                  </div>
                  <div>
                    {capitalizeStr(item?.name)}
                    {item?.isDefault && <span style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>(Default)</span>}
                  </div>
                </div>
              </Typography.Text>
              <div style={{display:"flex"}}>
                <EditOutlined onClick={() => handleEdit(item)} />
                {data?.length > 1 && <DeleteOutlined style={{ marginLeft: "10px" }} onClick={() => showDeleteConfirm(item)} />}
              </div>
            </List.Item>
          )}
        />
      )}
      {!isAddNew && editingItem && (
        <div>
          <Typography.Text strong style={{ fontSize: "18px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
              <div >
              <FaMedal style={{ marginRight: "10px",marginTop:"5px" }} />
              </div>
              <div>
              {capitalizeStr(editingItem?.name)}
                {editingItem?.isDefault && <span style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>(Default)</span>}
              </div>
            </div>
          </Typography.Text>
        </div>
      )}
      {/* // <ul>
      //   <li onClick={handleEdit}>{data?.designation}</li>
      // </ul> 
      //: renderForm()} */}
      {(isFormOpen || isAddNew) && (
        <>
          <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", color: "grey", marginBottom: "20px" }}>{isAddNew || data.length === 0 ? "Add" : "Edit"} Title</span>
          {renderForm()}
        </>
      )}
    </>
  );
}
export { TitleDetails };
