export const PropertyConstants ={

     SET_SELECTED_PROPERTY_DATA: "SET_SELECTED_PROPERTY_DATA",
     ADD_EMAIL_ERROR:"ADD_EMAIL_ERROR",
     ADD_EMAIL_LOADING:"ADD_EMAIL_LOADING",

     GET_PROPERTY_DATA_LOADING : "GET_PROPERTY_DATA_LOADING",
     GET_PROPERTY_DATA_SUCCESS : "GET_PROPERTY_DATA_SUCCESS",
     GET_PROPERTY_DATA_ERROR : "GET_PROPERTY_DATA_ERROR",

     GET_TASKS_DATA_LOADING: "GET_TASKS_DATA_LOADING",
     GET_TASKS_DATA_SUCCESS : "GET_TASKS_DATA_SUCCESS",
     GET_TASKS_DATA_ERROR: "GET_TASKS_DATA_ERROR",

     SET_TASKS_LOADING:"SET_TASKS_LOADING",

     GET_CLIENT_DOC_LOADING:"GET_CLIENT_DOC_LOADING",
     GET_CLIENT_DOC_SUCCESS:"GET_CLIENT_DOC_SUCCESS",
     GET_CLIENT_DOC_ERROR:"GET_CLIENT_DOC_ERROR",

     GET_PROPERTY_DOC_WITHOUT_SECTION_LOADING:"GET_PROPERTY_DOC_WITHOUT_SECTION_LOADING",
     GET_PROPERTY_DOC_WITHOUT_SECTION_SUCCESS:"GET_PROPERTY_DOC_WITHOUT_SECTION_SUCCESS",
     GET_PROPERTY_DOC_WITHOUT_SECTION_ERROR:"GET_PROPERTY_DOC_WITHOUT_SECTION_ERROR",

     UPLOAD_DOCUMENT_LOADING:"UPLOAD_DOCUMENT_LOADING",
     UPLOAD_DOCUMENT_SUCCESS:"UPLOAD_DOCUMENT_SUCCESS",
     UPLOAD_DOCUMENT_FAILURE:"UPLOAD_DOCUMENT_FAILURE",

     GET_PRE_DORA_DOCUMENTS_LOADING:"GET_PRE_DORA_DOCUMENTS_LOADING",
     GET_PRE_DORA_DOCUMENTS_SUCCESS:"GET_PRE_DORA_DOCUMENTS_SUCCESS",
     GET_PRE_DORA_DOCUMENTS_FAILURE:"GET_PRE_DORA_DOCUMENTS_FAILURE",

     SHARE_PROPERTY_DOCS_LOADING:"SHARE_PROPERTY_DOCS_LOADING",
     SHARE_PROPERTY_DOCS_SUCCESS:"SHARE_PROPERTY_DOCS_SUCCESS",
     SHARE_PROPERTY_DOCS_FAILURE:"SHARE_PROPERTY_DOCS_FAILURE",

     SHORTHAND_URL_LOADING:"SHORTHAND_URL_LOADING",
     SHORTHAND_URL_SUCCESS:"SHORTHAND_URL_SUCCESS",
     SHORTHAND_URL_FAILURE:"SHORTHAND_URL_FAILURE",

     NEED_TO_KNOW_FAILURE: "NEED_TO_KNOW_FAILURE",
     NEED_TO_KNOW_SUCCESS: "NEED_TO_KNOW_SUCCESS",
     NEED_TO_KNOW_LOADING: "NEED_TO_KNOW_LOADING",

     UPDATE_NEED_TO_KNOW: "UPDATE_NEED_TO_KNOW",

     GET_CONSTANTS_LOADING: "GET_CONSTANTS_LOADING",
     GET_CONSTANTS_SUCCESS: "GET_CONSTANTS_SUCCESS",
     GET_CONSTANTS_FAILURE: "GET_CONSTANTS_FAILURE",

     LINK_MLS_LOADING: 'LINK_MLS_LOADING',
     LINK_MLS_SUCCESS: 'LINK_MLS_SUCCESS',
     LINK_MLS_ERROR:'LINK_MLS_ERROR',

     DELINK_MLS_LOADING: 'DELINK_MLS_LOADING',
     DELINK_MLS_SUCCESS: 'DELINK_MLS_SUCCESS',
     DELINK_MLS_ERROR:'DELINK_MLS_ERROR',
     ADD_CUSTOM_TASK_ERROR:"ADD_CUSTOM_TASK_ERROR",
     ADD_CUSTOM_TASK_LOADING:"ADD_CUSTOM_TASK_LOADING",
     ADD_CUSTOM_TASK_SUCCESS:"ADD_CUSTOM_TASK_SUCCESS",

     POST_PROPERTY_IMAGE_LOADING:"POST_PROPERTY_IMAGE_LOADING",
     POST_PROPERTY_IMAGE_SUCESS:"POST_PROPERTY_IMAGE_SUCESS",
     POST_PROPERTY_IMAGE_ERROR:"POST_PROPERTY_IMAGE_ERROR",

     DELETE_PROPERTY_IMAGE_LOADING:"DELETE_PROPERTY_IMAGE_LOADING",
     DELETE_PROPERTY_IMAGE_SUCESS:"DELETE_PROPERTY_IMAGE_SUCESS",
     DELETE_PROPERTY_IMAGE_ERROR:"DELETE_PROPERTY_IMAGE_ERROR",

     EDIT_SELLERS_LOADING:"EDIT_SELLERS_LOADING",
     EDIT_SELLERS_SUCCESS:"EDIT_SELLERS_SUCCESS",
     EDIT_SELLERS_ERROR:"EDIT_SELLERS_ERROR",

     TASK_UPADTED:"TASK_UPADTED",

     CHANGE_FILTER_SORT: "CHANGE_FILTER_SORT",
     RESET_FILTER_SEARCH: "RESET_FILTER_SEARCH",

     RESET_PROPERTY_DOCS:"RESET_PROPERTY_DOCS",
     UPLOAD_DOCUMENT_PDF_SUCCESS: "UPLOAD_DOCUMENT_PDF_SUCCESS",

     GET_AGENT_DETAILS_LOADING: 'GET_AGENT_DETAILS_LOADING',
     GET_AGENT_DETAILS_SUCCESS: 'GET_AGENT_DETAILS_SUCCESS',
     SET_AGENT_DETAILS_DATA: "SET_AGENT_DETAILS_DATA",

     GET_OWNERS_INFO_LOADING: "GET_OWNERS_INFO_LOADING",
     GET_OWNERS_INFO_ERROR: "GET_OWNERS_INFO_ERROR",
     GET_OWNERS_INFO_SUCCESS: "GET_OWNERS_INFO_SUCCESS",
     
     GENERATE_DASHBOARD_LINK_LOADING: "GENERATE_DASHBOARD_LINK_LOADING",
     GENERATE_DASHBOARD_LINK_SUCCESS: "GENERATE_DASHBOARD_LINK_SUCCESS",
     GENERATE_DASHBOARD_LINK_ERROR: "GENERATE_DASHBOARD_LINK_ERROR",

     GET_PROPERTY_DOC_FOLDER_DATA_LOADING: "GET_PROPERTY_DOC_FOLDER_DATA_LOADING",
     GET_PROPERTY_DOC_FOLDER_DATA_SUCCESS: "GET_PROPERTY_DOC_FOLDER_DATA_SUCCESS",
     GET_PROPERTY_DOC_FOLDER_DATA_ERROR: "GET_PROPERTY_DOC_FOLDER_DATA_ERROR",

     GET_MOVE_TO_FOLDER_DATA_LOADING: "GET_MOVE_TO_FOLDER_DATA_LOADING",
     GET_MOVE_TO_FOLDER_DATA_SUCCESS: "GET_MOVE_TO_FOLDER_DATA_SUCCESS",
     GET_MOVE_TO_FOLDER_DATA_ERROR: "GET_MOVE_TO_FOLDER_DATA_ERROR",

     RESET_FOLDER_OPEN_STATE: "RESET_FOLDER_OPEN_STATE",
     TOGGLE_FOLDER_OPEN_STATE: "TOGGLE_FOLDER_OPEN_STATE",

     DOWNLOAD_ALL_FILES_AS_ZIP_REQUEST_LOADING: "DOWNLOAD_ALL_FILES_AS_ZIP_REQUEST_LOADING",
     DOWNLOAD_ALL_FILES_AS_ZIP_REQUEST_SUCCESS: "DOWNLOAD_ALL_FILES_AS_ZIP_REQUEST_SUCCESS",
     DOWNLOAD_ALL_FILES_AS_ZIP_REQUEST_ERROR: "DOWNLOAD_ALL_FILES_AS_ZIP_REQUEST_ERROR",
}
