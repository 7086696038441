import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, Divider, Space, Typography, message } from "antd";
import { clientAction } from "../state/actions";
import { v4 as uuidv4 } from "uuid";
import { capitalizeStr } from "../../Common/utils/extraFunctions";

const RelationDetails = ({ data, selectedClient, setModelDrawer }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);
  const [customOptions, setCustomOptions] = useState([]);
  const [customError, setCustomError] = useState("");
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [tempCustomRelation, setTempCustomRelation] = useState("");

  const predefinedOptions = [
    { id: uuidv4(), label: "Family", value: "Family" },
    { id: uuidv4(), label: "Friend", value: "Friend" },
    { id: uuidv4(), label: "Work", value: "Work" },
  ];

  useEffect(() => {
    let newCustomOptions = [...predefinedOptions];

    for (let relation of data) {
      let matchingOption = newCustomOptions.find((relationType) => relationType?.value?.toLowerCase() === relation?.toLowerCase());

      if (!matchingOption) {
        newCustomOptions.push({
          id: uuidv4(),
          label: relation, // ✅ Corrected: Use relation directly (it's a string)
          value: relation,
        });
      }
    }

    setCustomOptions(newCustomOptions);
  }, [data]);

  const addItem = () => {
    const trimmedValue = tempCustomRelation.trim().toLowerCase();

    if (!trimmedValue) return;

    const isDuplicate = customOptions.some((item) => item.value.trim().toLowerCase() === trimmedValue);

    if (isDuplicate) {
      setCustomError("This type already exists!");
      return;
    }

    const newOption = { id: uuidv4(), label: tempCustomRelation, value: tempCustomRelation };
    setCustomOptions([...customOptions, newOption]);
    setSelectedRelation(tempCustomRelation);
    setCustomError("");
    setTempCustomRelation("");
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [isAddNew]);

  const handleSave = () => {
    if (!selectedRelation) return;
    const isDuplicate = data.some((relation) => relation.toLowerCase() === selectedRelation.toLowerCase());

    if (isDuplicate) {
      // 🔹 Show a warning message instead of dispatching
      message.warning(`"${selectedRelation}" is already exist as relationship !`);
      return;
    }
    const updatedRelationships = [...data, selectedRelation];

    dispatch(clientAction.updateRelation({ relationships: updatedRelationships }, data, selectedClient));

    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
    form.resetFields();
    setSelectedRelation(null);
    setTempCustomRelation("");
    dispatch(clientAction?.resetAddNewRequest());
  };

  return (
    <div style={{ minHeight: "250px", maxHeight: "450px", overflowY: "auto", padding: "5px" ,marginTop:"-20px"}}>
    <Typography.Text strong style={{ fontSize: "18px",color:"grey",marginBottom:"10px" }}>
    Add Relationships
    </Typography.Text>
      <Select
        style={{ width: "100%", marginBottom: "10px",marginTop:"10px" }}
        placeholder="Select Relation Type"
        value={selectedRelation}
        onChange={(value) => setSelectedRelation(value)}
        dropdownRender={(menu) => (
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px", width: "100%" }}>
              <Input
                placeholder="Enter custom type"
                ref={inputRef}
                value={tempCustomRelation}
                onChange={(e) => {
                  setTempCustomRelation(e.target.value);
                  setCustomError("");
                }}
                onPressEnter={addItem}
                style={{ flex: 1 }}
              />
              <Button type="primary" onClick={addItem}>
                Add
              </Button>
            </Space>
            {customError && <Typography.Text type="danger">{customError}</Typography.Text>}
          </div>
        )}
        options={customOptions.map((item) => ({ label: capitalizeStr(item?.label), value: item?.value }))}
      />

      {/* Save Button Moved to Bottom */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
      <Button
          onClick={() => {
            form.resetFields();
            setSelectedRelation(null);
            setTempCustomRelation("");
            dispatch(clientAction?.resetAddNewRequest());
          }}
          disabled={!selectedRelation}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={handleSave} disabled={!selectedRelation}>
          Save
        </Button>
  
      </div>
    </div>
  );
};

export { RelationDetails };
