import { message, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { BiUser } from "react-icons/bi";
import styles from "../styles/Client.module.css";
import { NameDetails } from "./NameDetails";
import { capitalizeStr } from "../../Common/utils/extraFunctions";
import { useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";

const Name = ({ setModelDrawer, onFinish, isAddNew, selectedClient,onDeleteName }) => {
  const { personDetails } = useSelector((state) => state.client.personDetailReducer);
  
  const [localNames, setLocalNames] = useState([
    {
      firstName: personDetails?.firstName || "",
      middleName: personDetails?.middleName || "",
      lastName: personDetails?.lastName || "",
      fullName: `${personDetails?.firstName || ""} ${personDetails?.middleName || ""} ${personDetails?.lastName || ""}`.trim(),
      isDefault: true,
    },
  ]);
  useEffect(() => {
    if (personDetails?.names?.length > 0) {
      setLocalNames(personDetails.names);
    } else {
      setLocalNames([
        {
          firstName: personDetails?.firstName || "",
          middleName: personDetails?.middleName || "",
          lastName: personDetails?.lastName || "",
          fullName: `${personDetails?.firstName || ""} ${personDetails?.middleName || ""} ${personDetails?.lastName || ""}`.trim(),
          isDefault: true,
        },
      ]);
    }
  }, [personDetails]);

  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Client Name"} placement="left">
          <BiUser className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: <NameDetails onFinish={onFinish} personId={personDetails?._id} setModelDrawer={setModelDrawer} data={personDetails?.names ? personDetails?.names : localNames} isAddNew={isAddNew} selectedClient={selectedClient} onDeleteName={onDeleteName}/>,
            title: "Name Details",
            toolTip: "Add Another Name",
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-name.svg" alt=""></img>
        </div>
        <div style={{ marginLeft: "-10%", width: "190px", height: "25px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} className={styles.userfeild}>
          {(personDetails.firstName ? capitalizeStr(personDetails.firstName) : "") + " " + (personDetails.middleName && personDetails.middleName !== "undefined" && personDetails.middleName !== "null" ? capitalizeStr(personDetails.middleName) : "") + " " + (personDetails.lastName ? capitalizeStr(personDetails.lastName) : "")}
        </div>
      </div>
    </Text>
  );
};

export { Name };
