export const templateConstants = {
  TEMPLATE_DATA_LOADING: "TEMPLATE_DATA_LOADING",
  TEMPLATE_DATA_FAILURE: "TEMPLATE_DATA_FAILURE",
  TEMPLATE_DATA_SUCCESS: "TEMPLATE_DATA_SUCCESS",

  DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_LOADING: "DELETE_TEMPLATE_LOADING",

  TEMPLATE_SHORTHAND_URL_LOADING: "TEMPLATE_SHORTHAND_URL_LOADING",
  TEMPLATE_SHORTHAND_URL_SUCCESS: "TEMPLATE_SHORTHAND_URL_SUCCESS",
  TEMPLATE_SHORTHAND_URL_FAILURE: "TEMPLATE_SHORTHAND_URL_FAILURE",

  TEMPLATE_EDITDATA_FAILURE: "TEMPLATE_EDITDATA_FAILURE",
  TEMPLATE_EDITDATA_SUCCESS: "TEMPLATE_EDITDATA_SUCCESS",

  GET_CONTRACT_DOCUMENTARIES_DATA_LOADING: "GET_CONTRACT_DOCUMENTARIES_DATA_LOADING",
  GET_CONTRACT_DOCUMENTARIES_DATA_SUCCESS: "GET_CONTRACT_DOCUMENTARIES_DATA_SUCCESS",
  GET_CONTRACT_DOCUMENTARIES_DATA_FAILURE: "GET_CONTRACT_DOCUMENTARIES_DATA_FAILURE",
};
