import { Tooltip, Typography } from "antd";
import React from "react";
import { HiHome } from "react-icons/hi";
import styles from "../styles/Client.module.css";
import { AddressDetails } from "./AddressDetails";
import { formatAddress } from "../../Common/utils/extraFunctions";
import { formatCLientAddress } from "../../Common/utils/extraFunctions";

const Address = ({ personDetails, setModelDrawer, selectedClient }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Address"} placement="left" >
          <HiHome className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        style={{ marginLeft: "5px" }}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              <AddressDetails
                selectedClient={selectedClient}
                setModelDrawer={setModelDrawer}
                data={personDetails && personDetails.addressData}
              />
            ),
            title: "Address Details",
            toolTip: "Add Address"
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-address.svg" alt=""></img>
        </div>
        <div style={{ width:"85%",marginLeft:"-34px" }} className={styles.userfeild}>
          {" "}
          <Text style={{width:"100%",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}} ellipsis className={styles.addressField}>
            {formatCLientAddress(personDetails?.addressData) || "Enter Address"}
          </Text>
        </div>
      </div>
    </Text>
  );
};

export { Address };
