/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, List, Typography, Checkbox, Select, Divider, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";
import { clientAction } from "../state/actions";
import confirm from "antd/lib/modal/confirm";
import { v4 as uuidv4 } from "uuid";
import { capitalizeStr } from "../../Common/utils/extraFunctions";

function EditEmailDetails({ data, onFinish, onDeleteEmail }) {
  const inputRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [editingItem, setEditingItem] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [tempCustomRelation, setTempCustomRelation] = useState("");

  const [customError, setCustomError] = useState("");
  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);
  const [customOptions, setCustomOptions] = useState([]);
  const predefinedOptions = [
    { id: uuidv4(), label: "Personal", value: "PERSONAL" },
    { id: uuidv4(), label: "Work", value: "WORK" },
    { id: uuidv4(), label: "Temporary", value: "TEMPORARY" },
  ];

  useEffect(() => {
    let newCustomOptions = [...predefinedOptions]; // ✅ Start with predefined options

    for (let email of data) {
      email.type = email?.type ? email.type.toUpperCase() : "PERSONAL";

      let matchingOption = newCustomOptions.find((emailType) => emailType.value.toLowerCase() === email.type.toLowerCase());

      if (!matchingOption) {
        newCustomOptions.push({
          id: uuidv4(),
          label: email?.type,
          value: email?.type.toUpperCase(),
        });
      }
    }

    setCustomOptions(newCustomOptions);
  }, [data]);

  const groupedAndSortedEmails = () => {
    if (!data) return {};

    // 1️⃣ Sort: Default emails should come first
    const sortedData = [...data].sort((a, b) => (b.isDefault ? 1 : -1));

    // 2️⃣ Dynamically create groups based on unique email types
    let grouped = {};
    let defaultSection = null; // Track which section has the default email

    sortedData.forEach((item) => {
      const type = item.type; // No need for "Other" fallback

      if (!grouped[type]) {
        grouped[type] = []; // Initialize group if not exists
      }

      grouped[type].push(item);

      if (item.isDefault) {
        defaultSection = type; // Store the section where default exists
      }
    });

    // 3️⃣ If there's a default section, move it to the top
    if (defaultSection) {
      const reorderedGroups = { [defaultSection]: grouped[defaultSection] };

      // Add remaining sections in order, skipping the default one since it's already added
      Object.keys(grouped).forEach((key) => {
        if (key !== defaultSection) {
          reorderedGroups[key] = grouped[key];
        }
      });

      return reorderedGroups;
    }

    return grouped;
  };

  const emailsGroup = groupedAndSortedEmails();
  // Handle Edit
  const handleEdit = (item) => {
    setEditingItem(item);
    dispatch(clientAction.resetAddNewRequest());
    form.setFieldsValue({
      email: item?.email || "",
      isPrimary: item?.isDefault || false,
      type: item?.type.toUpperCase() || "",
    });
    setIsFormOpen(true);
  };

  const handleFormSubmit = (values) => {
    const isPrimary = values.isPrimary || false;
    let updatedEmails = data.map((item) => {
      if (item === editingItem) {
        return {
          ...item,
          email: values.email,
          isDefault: editingItem.isDefault ? true : isPrimary,
          type: values.type.toUpperCase(),
        };
      }
      return isPrimary ? { ...item, isDefault: false } : item;
    });

    if (!editingItem) {
      updatedEmails.push({
        email: values.email,
        isDefault: isPrimary,
        type: values.type.toUpperCase(),
      });
    }

    let updatedData = { emails: updatedEmails };

    if (isPrimary || editingItem?.isDefault) {
      updatedData.email = values.email;
      updatedData.type = values.type;
    }

    onFinish(updatedData);
    form.resetFields();
    setIsFormOpen(false);
    setEditingItem(null);
    dispatch(clientAction.resetAddNewRequest());
  };

  const showDeleteConfirm = (emailToDelete) => {
    confirm({
      title: "Are you sure you want to remove this email?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(emailToDelete);
      },
    });
  };

  const handleDelete = (emailToDelete) => {
    let updatedEmails = data.filter((item) => item._id !== emailToDelete._id);
    let newPrimary = updatedEmails.length > 0 && emailToDelete.isDefault ? updatedEmails[0] : null;

    if (newPrimary) {
      updatedEmails[0] = { ...updatedEmails[0], isDefault: true };
    }
    let updatedData = { emails: updatedEmails };
    if (newPrimary) {
      updatedData.email = newPrimary.email;
      updatedData.type = newPrimary.type;
    }

    onDeleteEmail(updatedData);
  };

  // State for error message

  const addItem = () => {
    const trimmedValue = tempCustomRelation.trim().toLowerCase();

    if (!trimmedValue) {
      return;
    }

    // Check if the value already exists in customOptions
    const isDuplicate = customOptions.some((item) => item.value.trim().toLowerCase() === trimmedValue);

    if (isDuplicate) {
      setCustomError("This type already exists!");
      return;
    }

    // Add new custom type if it's not duplicate
    const newOption = { id: uuidv4(), label: tempCustomRelation, value: tempCustomRelation };
    setCustomOptions([...customOptions, newOption]);
    setSelectedRelation(tempCustomRelation);
    setCustomError(""); // Clear error after successful add
    setTempCustomRelation(""); // Clear input
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFormOpen]);

 useEffect(() => {
      if (data.length === 0) {
       form.setFieldsValue({ isPrimary: true });
        return;
      } else {
        form?.resetFields();
        setEditingItem(null);
      }
    }, [isAddNew]);
  
    
    useEffect(() => {
      if (data.length === 0) {
       form.setFieldsValue({ isPrimary: true });
        setIsFormOpen(true);
        dispatch(clientAction.addNewRequest())
      }
    }, [data,form]);
  const renderForm = () => (
    <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={handleFormSubmit} autoComplete="off">
      <Form.Item label="Email" name="email" hasFeedback rules={[{ type: "email", message: "Not a valid Email!" }, { required: true, message: "Please enter email!" }, { whitespace: true }]}>
        <Input ref={inputRef} placeholder="Enter Email" allowClear autoFocus />
      </Form.Item>
      <Form.Item name="type" label="Email Type" rules={[{ required: true, message: "Please enter email type!" }]}>
        <Select
          style={{ width: 350 }}
          placeholder="Select Email type"
          value={selectedRelation}
          onChange={(value) => setSelectedRelation(value)}
          dropdownRender={(menu) => (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <div>
                  <Input
                    placeholder="Enter custom type"
                    ref={inputRef}
                    value={tempCustomRelation}
                    onChange={(e) => {
                      setTempCustomRelation(e.target.value);
                      setCustomError("");
                    }}
                    onPressEnter={addItem}
                  />
                  {customError && <Typography.Text type="danger">{customError}</Typography.Text>}
                </div>
                <Button type="text" onClick={addItem}>
                  Add
                </Button>
              </Space>
            </div>
          )}
          options={customOptions.map((item) => ({ label: capitalizeStr(item?.label), value: item?.value }))}
        />
      </Form.Item>
      {!editingItem?.isDefault && (
        <Form.Item name="isPrimary" valuePropName="checked" disabled={data.length === 0} wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox disabled={data.length === 0 || editingItem?.isDefault}>Make this the primary email</Checkbox>
        </Form.Item>
      )}
      <Form.Item wrapperCol={{ span: 24 }}>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button
            onClick={() => {
              setIsFormOpen(false);
              setEditingItem(null);
              form.resetFields()
              dispatch(clientAction.resetAddNewRequest());
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  return (
    <>
      {!(isAddNew || isFormOpen) && (
        <div style={{ maxHeight: "200px", overflowY: "auto", width: "100%", padding: "5px", marginTop: "-20px" }}>
          {Object.keys(emailsGroup).map((type) => (
            <div key={type} style={{ marginTop: "10px" }}>
              <Typography.Title level={4} style={{ marginBottom: "-3px" }}>
                {type ? `${capitalizeStr(type)} Email` : "Personal Email"}
              </Typography.Title>
              <List
                dataSource={emailsGroup[type]}
                renderItem={(item) => (
                  <List.Item style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography.Text>
                      {" "}
                      <MailOutlined style={{ marginRight: "10px" }} /> {item.email}
                      <text style={{ fontSize: "14px", color: "grey" }}> {item?.isDefault ? " (Default)" : ""}</text>
                    </Typography.Text>
                    <div>
                      <EditOutlined onClick={() => handleEdit(item)} />
                      {data?.length > 1 && <DeleteOutlined style={{ marginLeft: "10px" }} onClick={() => showDeleteConfirm(item)} />}
                    </div>
                  </List.Item>
                )}
              />
            </div>
          ))}
        </div>
      )}
      {!isAddNew && editingItem && (
        <>
          <Typography.Title level={4} style={{ marginBottom: "0px" }}>
            {editingItem?.type ? `${capitalizeStr(editingItem.type)} Email` : "Personal Email"}
          </Typography.Title>
          <Typography.Text style={{ fontSize: "18px" }}>
            <MailOutlined style={{ marginRight: "10px" }} />
            {editingItem?.email}

            <text style={{ fontSize: "14px", color: "grey" }}> {editingItem?.isDefault ? " (Default)" : ""}</text>
          </Typography.Text>
        </>
      )}
      {(isFormOpen || isAddNew) && (
        <>
          <Typography.Text style={{ display: "block", textAlign: "center", fontWeight: "bold", fontSize: "18px", color: "grey", margin: "10px 0 20px" }}>{isAddNew || data.length === 0 ? "Add" : "Edit"} Email</Typography.Text>
          {renderForm()}
        </>
      )}
    </>
  );
}

export { EditEmailDetails };
