/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { buildPropertyStreetAddress } from "../../Common/utils/extraFunctions";

const SubjectLine = ({ form,  data, source, processClientDocs }) => {
  const { getIndividualTransactionData, getPropertyDocsData } = useSelector((state) => state.rtdTransactionLane);
  const { personDocs } = useSelector((state) => state.client.personDetailReducer, shallowEqual);
  const { propertyDocsWithoutSection, contactDocs } = useSelector((state) => state.PropertyReducer, shallowEqual);
  const [documentName, setDocumentName] = useState([]);
  const inputChanged = useRef(false);

  const streetAddress = useMemo(() => buildPropertyStreetAddress(getIndividualTransactionData?.addressDetails), [getIndividualTransactionData]);

  const clientsArray = useMemo(() => {
    return source === "CLIENT_DOC" ? processClientDocs(personDocs) : [];
  }, [source, personDocs]);

  const getDocumentNames = (docNames, selectedDocArray, dataArray, tab) => {
    for (let i = 0; i < selectedDocArray?.length; i++) {
      for (let j = 0; j < dataArray?.length; j++) {
        if (tab?.length) {
          if (tab === "SHARED") {
            if (selectedDocArray[i] === dataArray[j]?._id) {
              docNames.push(dataArray[j]?.fileName);
            }
          } else if (tab === "CLIENTS") {
            if (selectedDocArray[i].documentId === dataArray[j]?._id) {
              docNames.push(dataArray[j]?.contractName);
            }
          } else if (tab === "CUSTOMERS") {
            if (selectedDocArray[i] === dataArray[j]?._id) {
              docNames.push(dataArray[j]?.documentName);
            }
            dataArray?.[j]?.matchedDocuments?.forEach((ele)=>{
              if (selectedDocArray[i] === ele?._id) {
                docNames.push(ele?.documentName);
              }
            })
          } else {
            if (dataArray[j]?.contractId) {
              if (selectedDocArray[i] === dataArray[j]?.documentId) {
                docNames.push(dataArray[j]?.documentName);
              }
            } else {
              if (selectedDocArray[i] === dataArray[j]?._id) {
                docNames.push(dataArray[j]?.fileName);
              }
            }
          }
        } else {
          if (dataArray[j]?.documentId) {
            if (selectedDocArray[i] === dataArray[j]?.documentId) {
              docNames.push(dataArray[j]?.documentName);
            }
          } else {
            if (selectedDocArray[i] === dataArray[j]?._id) {
              docNames.push(dataArray[j]?.fileName);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!inputChanged.current) {
      const docNamesArray = [];
      if (data?.propertyDocsSelected?.length) getDocumentNames(docNamesArray, data?.propertyDocsSelected, propertyDocsWithoutSection);
      if (data?.sharedSelected?.length) getDocumentNames(docNamesArray, data?.sharedSelected, getPropertyDocsData, "SHARED");
      if (data?.documentWithoutBuyerSelected?.length) getDocumentNames(docNamesArray, data?.documentWithoutBuyerSelected, propertyDocsWithoutSection, "WithoutBuyers");
      if (data?.clientDocumentSelected?.length) getDocumentNames(docNamesArray, data?.clientDocumentSelected, clientsArray, "CLIENTS");
      if (data?.customerDocumentSelected?.length) getDocumentNames(docNamesArray, data?.customerDocumentSelected, contactDocs, "CUSTOMERS");
      setDocumentName(docNamesArray.length ? (source === "CLIENT_DOC" ? (docNamesArray.length === 1 ? docNamesArray[0]?.trim() : "Document Links") : docNamesArray.length === 1 ? `: ${docNamesArray[0]?.trim()}` : ": Document Links") : "");
    }
  }, [data, clientsArray, contactDocs, getPropertyDocsData, propertyDocsWithoutSection, source]);

  useEffect(() => {
    let subject = "";
    if (source === "CLIENT_DOC") {
      subject = documentName || "";
    } else {
      subject = (streetAddress || "") + (documentName ? `${documentName}` : "");
    }
    form.setFieldsValue({ subject });
  }, [documentName, streetAddress, source]);

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Form.Item label="Subject" name="subject">
        <Input onChange={() => (inputChanged.current = true)} />
      </Form.Item>
    </Form>
  );
};

export default SubjectLine;
