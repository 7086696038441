/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { useMediaQuery } from "react-responsive";
import { message, Modal, Radio, Select, Spin } from "antd";
import { ERTSAction } from "../state/action";
import { useEffect, useState } from "react";
import axios from "axios";
import { toolsAction } from "../../Tools/state/actions";
import { Option } from "antd/lib/mentions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreateERTS = ({ setisModalOpen, next, importModalStep, setErtsType, setDocumentSetId, listingModalFields,  delegate }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const { ERTS_Loading, checkIsMarkEibnerBrokerageError, MEBrokerageAccess,CBFormsAccess, checkIsMarkEibnerBrokerageLoading } = useSelector((state) => state.ERTSReducer);
  const { documentSetData, documentSetLoading } = useSelector((state) => state.ToolReducers.DocSetReducer);

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleSelectClick = () => {
    if (!documentSetLoading && (!documentSetData || documentSetData?.length === 0)) {
      setShowModal(true);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalOk = () => {
    setShowModal(false);
    history.push("/tools/document_sets");
  };


  const handleUploadClick = () => {
    setisModalOpen((prevState) => ({
      ...prevState,
      docsModal: true,
      importModalStep: 0,
    }));
  };

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();

  useEffect(() => {
    if (!delegate) {
      dispatch(ERTSAction.checkIsMarkEibnerBrokerage({ unmounted, sourceAxios }));
      dispatch(toolsAction.getDocSetData({ unmounted, sourceAxios, onlyTitle: true }))
    }
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    }
  }, []);

  useEffect(() => {
    const delegateOwnerId = listingModalFields?.delegateSeller?.id;
    if (delegate && delegateOwnerId) {
      dispatch(ERTSAction.checkIsMarkEibnerBrokerage({ unmounted, sourceAxios , delegateOwnerId}));
      dispatch(toolsAction.getDocSetData({ unmounted, sourceAxios, onlyTitle: true, delegateOwnerId }));
    }
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    };
  }, [listingModalFields?.delegateSeller]);


  useEffect(() => {
    if (MEBrokerageAccess) {
      setErtsType({
        contractId: "678103bebb15f25706c38f74",
        contractType: "FRASCONA_ERTS"
      })
    } else if (CBFormsAccess) {
      setErtsType({
        contractId: "67e4f325551c3a9bff7fd62e",
        contractType: "CB_ERTS"
      })
    }
  }, [MEBrokerageAccess, CBFormsAccess]);

  return ERTS_Loading ? (
    <div style={{ marginTop: "15%", height: isMobile ? "300px" : "500px" }}>
      <Loading />
    </div>
  ) : (
    <>
    <div
      style={
        isMobile && importModalStep === 1
          ? { marginTop: "-40px" }
          : { height: "100%", margin: "auto" }
      }
    >
        {checkIsMarkEibnerBrokerageError ? message.error(checkIsMarkEibnerBrokerageError) :
          checkIsMarkEibnerBrokerageLoading ? <div style={{textAlign: 'center'}}><Spin /></div> :
           (MEBrokerageAccess || CBFormsAccess) && (
              <div style={{position: 'relative', border: '1px solid silver', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{position: 'absolute', top: -12, left: 10, padding: '0 5px', backgroundColor: 'white', fontWeight: 'bold', color: "grey"}}>ERTS Type</span>
              <Radio.Group
                style={{ margin: "10px" }}
                onChange={(e) =>
                  setErtsType({
                    contractType: e.target.value,
                    contractId:
                      e.target.value === "ERTS"
                        ? "66aa0f8695a14f0a36fc6e12" // ERTS
                        : e.target.value === "CB_ERTS"
                        ? "67e4f325551c3a9bff7fd62e"
                        : "678103bebb15f25706c38f74", // FRASCONA_ERTS
                  })
                }
               defaultValue={CBFormsAccess ? "CB_ERTS" : "FRASCONA_ERTS"}
              >
                <Radio value={CBFormsAccess ? "CB_ERTS" : "FRASCONA_ERTS"} style={{ display: "block" }}>
                  {CBFormsAccess ? "CB Exclusive Right to Sell" : "Custom Exclusive Right to Sell"}
                </Radio>
                <Radio value="ERTS" style={{ display: "block" }}>
                  DORA Exclusive Right to Sell
                </Radio>
              </Radio.Group>
              </div>
            )}
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin:"10px 0",
            }}>
              <Select
                style={{
                  width: "250px",
                  backgroundColor: "white",
                  border: "1px solid black",
                }}
                showSearch
                placeholder="Document Set(Optional)"
                optionFilterProp="children"
                onChange={setDocumentSetId}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                onClick={handleSelectClick}
                notFoundContent=""
            >
              {documentSetLoading ? (
              <div style={{ minHeight: "20vh", display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                <Spin size="medium" />
              </div>
            ) : (
              documentSetData?.map((ele, index) => (
                <Option key={index} value={ele?._id}>
                  {ele?.name}
                </Option>
              ))
            )}
              </Select>
            </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          onClick={() => {
            next();
          }}
        >
          <span
            style={{ color: "#2C59A9", fontSize: "25px", cursor: "pointer" }}
          >
            Create
          </span>
        </div>
        <div>
          <span style={{ color: "grey", fontSize: "15px" }}>
            ------ OR ------
          </span>
        </div>
        <div onClick={handleUploadClick}>
          <span
            style={{
              color: "#2C59A9",
              fontSize: "22px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>Upload</div>
            <div style={{ fontSize: "18px", textAlign: "center" }}>
              (Existing PDF of Exclusive Right To Sell Contract)
            </div>
          </span>
        </div>
      </div>
    </div>

    {showModal && (
      <Modal
        title={<div style={{fontWeight: 'bold', textAlign: 'center', color: '#178DFA'}}>NO DOCUMENT SET FOUND</div>}
        open={showModal}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        okText="CREATE DOCUMENT SET"
        cancelText="CANCEL"
      >
        <p>
          Looks like you don't have any document sets in your account. Please go to <span style={{fontWeight: 'bold'}}>Tools</span>, then click on <span style={{fontWeight: 'bold'}}>Documents Sets</span> menu.
          On the upper left hand corner click on the <span style={{fontWeight: 'bold'}}>Create Document Set</span> button.
        </p>
        {/* <p>
          Or you can refer the link below to watch a tutorial video:- <br />
          <a href="https://www.loom.com/share/c70314f257584a0f9d68d8e2085edd32" target="_blank" rel="noopener noreferrer">Watch Video</a>
        </p> */}
      </Modal>
    )}
    </>
  );
};

export { CreateERTS };

