
import axios from 'axios';
const apiURL = process.env.REACT_APP_BASE_URL;
const puppeteerApiUrl = process.env.PUPPETEER_API_URL;

export const checkDuplicateZipFileNames = async (filenames) => {
  const accessToken = localStorage.getItem("userAuth");
  try {
    const response = await axios.post(
      `${apiURL}/checkDuplicateZipFileNames`,
      { filenames },
      {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response?.data?.info;
  } catch (error) {
    throw error;
  }
};

