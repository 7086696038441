import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import PersonsList from "../../Common/components/PersonsList";
import { makeFirstLetterCapital } from "../../Common/utils/extraFunctions";
import { documentAction } from "../state/actions";
import { v4 as uuidv4 } from "uuid";

const EditAgents = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { agentsArray } = useSelector((state) => state.documentSection.CorpReducer);

  const [editAgentState, setEditAgentState] = useState({
    currentAgent: {},
    editMode: false,
    isAddingNew: false,
  });

  const handleDataChange = (updatedAgents={}) => {
    dispatch(documentAction.setpdfImportState(updatedAgents));
  };
  const handleDeleteAgent = (agent) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${agent.fullName || "this Agent"} from the list?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        let newData = agentsArray.filter((el) => el.key !== agent.key);
        handleDataChange({ BuyerAgents: newData });
        message.success(`${agent.fullName || "Agent"} removed successfully.`);
      },
    });
  };

  const handleEdit = (agent) => {
    setEditAgentState({
      currentAgent: agent,
      editMode: true,
      isAddingNew: false,
    });

    form.setFieldsValue({
      fullName: agent.fullName || "",
      email: agent.email || "",
    });
  };

  const handleAddNew = () => {
    setEditAgentState({
      currentAgent: {},
      editMode: true,
      isAddingNew: true,
    });

    form.resetFields();
  };

  const onFinish = (values) => {
    if (editAgentState.isAddingNew) {
      let newagentObj = {
        fullName: values.fullName,
        email: values.email,
        firmName: "",
        resureFirmId: "",
        firmLicense: "",
        license: "",
        address: "",
        phoneNumber: "",
        faxNumber: "",
        personId: null,
        key: uuidv4(),
      };
      let newData = [...agentsArray, { ...newagentObj }];
      handleDataChange({ BuyerAgents: newData });
      message.success("New agent added successfully.");
    } else {
      let newData = agentsArray.map((agent) => (agent.key === editAgentState.currentAgent.key ? { ...agent, ...values } : agent));
      handleDataChange({ BuyerAgents: newData });
      message.success("Agent details updated successfully.");
    }
    setEditAgentState({ currentAgent: {}, editMode: false, isAddingNew: false });
    form.resetFields();
  };

  return (
    <div style={{ height: "50vh", overflowY: "auto", width: "100%", padding: "0px" }}>
      {!editAgentState?.editMode && (
        <div style={{ position: "absolute", top: "10px", right: "30px" }}>
          <Tooltip title="Add New Agent" trigger={["hover", "click"]}>
            <Button type="primary" onClick={handleAddNew}>
              Add New
            </Button>
          </Tooltip>
        </div>
      )}
      {!editAgentState?.editMode && <PersonsList dataSource={agentsArray} onDelete={handleDeleteAgent} handleEdit={handleEdit} showDeleteIcon={true} showEditIcon={true} />}

      {editAgentState?.editMode && (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} autoComplete="off" style={{ marginTop: "20px" }}>
          <Form.Item label="Full Name" name="fullName" rules={[{ required: true, message: "Please Enter Full Name!" }]}>
            <Input placeholder="Enter Full Name" allowClear autoFocus />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            hasFeedback
            rules={[
              {
                type: "email",
                message: "Not a valid Email!",
              },
              {
                required: true,
                message: "Please Enter Email!",
              },
              { whitespace: true },
            ]}
          >
            <Input placeholder="Enter Email" allowClear />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <Button type="primary" htmlType="submit">
                {editAgentState.isAddingNew ? "Add Agent" : "Save"}
              </Button>
              <Button onClick={() => setEditAgentState({ currentAgent: {}, editMode: false, isAddingNew: false })} style={{ marginLeft: "10px" }}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditAgents;
