/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Popover, Modal, Form } from "antd";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SearchOutlined, FilterFilled, PlusCircleOutlined } from "@ant-design/icons";
import FilterMenu from "./FilterMenu";
import { useHistory, useLocation } from "react-router-dom";
import styles from "../styles/PropertyNav.module.css";
import { useMediaQuery } from "react-responsive";
import SmallScreenFilters from "./SmallScreenFilters";
import { documentAction } from "../../DocumentSection/state/actions";
import { ErtsModel } from "../../ErtsModel/ErtsModel";
import moment from "moment";
import { DocumentSection } from "../../DocumentSection";
import { defaultERTSData, handleDateSortClick, isResetFilterSort, transactionDefaultSortFilter } from "../../Common/commondata/commonData";
import { UpDownArrows } from "../../Common/components/CommonMiniComponents";
import _ from 'lodash';
import { accountHandle } from "../../Account/state/actions";
import axios from "axios";
import { PropertyHandlers } from "../state/actions";
import { roleAccessFunc } from "../../Common/utils/extraFunctions";

const PropertyNavbar = ({section}) => {
  const delegate =  (section === "DELEGATE");
  const limited =  (section === "LIMITED_TRANSACTION");

  const location = useLocation()
  const { modalState } = location.state || { modalState: {} };

  const [toggled, setToggled] = useState({
    filter: false
  });
  const [isModalOpen, setisModalOpen] = useState({
    isOfferModal: false,
    isListingModal: false,
    docsModal :false,
    importModalStep:0,
  });
  const [addPersons,setAddPerson]=useState(false)
  const [addCorps,setAddCorps]=useState(false)
  const [listingModalFields, setListingModalFields] = useState({
    ...defaultERTSData({moment})
  });
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  const [form] = Form.useForm();
  const [formOne] = Form.useForm();
  const screenWidth = window.innerWidth;
  const { isOfferModal ,isListingModal, docsModal, importModalStep} = isModalOpen;
  const { getCategoryData } = useSelector((state) => state.documentSection.categoryReducer, shallowEqual);
  // const isMax = useMediaQuery({maxWidth: 1045});
  // const secondMax = useMediaQuery({maxWidth: 845});
  const thirdMax = useMediaQuery({maxWidth: 705});
  const [progress, setProgress] = useState(0);
  const [formFields, setFormFields] = useState({
    contractId: getCategoryData?.[0]?.contractId,
    contractType: getCategoryData?.[0]?.contractType,
    name: getCategoryData?.[0]?.name,
  })
  const { getUserDetailsData, getLicenseData, roleAccessed } = useSelector((state) => state.account);
  const {clearForm, filterSort} = useSelector((state) => state.PropertyReducer);
  const isDelegateUsingMyAccount = !!getUserDetailsData?.delegateId?._id;
  const isSuperAdmin = !!getUserDetailsData?.delegateId?.adminRole?.includes("SUPER_ADMIN");
  const filteredDelegate = isDelegateUsingMyAccount ? getUserDetailsData?.delegatesAssigned?.filter((item) => String(item?.personId) === String(getUserDetailsData?.delegateId?._id)) : [];
  const isDelegateHasLimitedAccess = filteredDelegate?.[0]?.specificTransaction || false;
  
  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  // debounce logic to call search api
  const debouncedFunction = _.debounce((value) => {
    dispatch(PropertyHandlers.changeFilterSort({
      ...filterSort,
      search: value
    }))
  }, 1000);

  const handleChange = (event) => {
    const value = event.target.value;
    debouncedFunction(value);
  };

  const handleClear =({renderWithClear})=>{
    if(isResetFilterSort({filterSort})){
      dispatch(PropertyHandlers.changeFilterSort({
        ...transactionDefaultSortFilter
      }, true))
    }
    else if(renderWithClear){
      dispatch(PropertyHandlers.changeFilterSort({
        ...transactionDefaultSortFilter
      }))
    }
  }

  const showErtsModel = () => {
    let licensePresent = getLicenseData?.filter((el) => el.idType === "COLORADO STATE LICENSE");
      if(licensePresent?.length || roleAccessFunc(roleAccessed, "CREATE_OFFER")){
        dispatch(documentAction.resetCorpState());
        dispatch(documentAction.setCorpType(false));
        setisModalOpen({...isModalOpen,isListingModal:true,importModalStep:0})
      }else{
        Modal.confirm({
          title: "Colorado State License",
          content: "Please add your Colorado State License number to continue.",
          okText:"Add",
          cancelText:"Cancel",
          onOk(){
            history.push({pathname: "/account"});
          },
          onCancel() {}
        })
      }
  };

  const handleRedirectToTC = () => {
    history.push({pathname: "/account/delegates/transactions"});
    dispatch(PropertyHandlers.resetFilterSearch());
  }

  const handleCancelErts = () => {
    dispatch(documentAction.resetCorpState());
    dispatch(documentAction.setCorpType(false));
    setisModalOpen({...isModalOpen, isListingModal:false, docsModal:false, importModalStep:0});
    if(docsModal){
        dispatch(documentAction.clearUrlData());
    }
    setListingModalFields({
      ...defaultERTSData({moment})
    });
    setAddPerson(false)
    setAddCorps(false)
  };

  const showOfferModal = () => {
    let licensePresent = getLicenseData?.filter((el) => el.idType === "COLORADO STATE LICENSE");
    if(licensePresent?.length || roleAccessFunc(roleAccessed, "CREATE_OFFER")){
      dispatch(documentAction.resetCorpState());
      dispatch(documentAction.setCorpType(false));
      setisModalOpen({...isModalOpen,isOfferModal:true});
    }else{
      Modal.confirm({
        title: "Colorado State License",
        content: "Please add your Colorado State License number to continue.",
        okText:"Add",
        cancelText:"Cancel",
        onOk(){
          history.push({pathname: "/account"});
        },
        onCancel() {}
      })
    }
    // setisModalOpen({...isModalOpen,isOfferModal:true});
  };

  const handleCloseModal = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to cancel?",
      okText:"Yes",
      cancelText:"No",
      onOk() {
        handleCancel();
      },
      onCancel() {},
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setFormFields({
      ...formFields,
      contractId:getCategoryData?.[0]?.contractId,
      contractType:getCategoryData?.[0]?.contractType,
      name:getCategoryData?.[0]?.name
    })
    setisModalOpen({...isModalOpen,isOfferModal:false});
    setProgress(0);
    dispatch(documentAction.resetCorpState());
    dispatch(documentAction.setCorpType(false));
    dispatch(documentAction.resetAddProperty());
    dispatch(documentAction.resetSearchProperties());

    dispatch(
      documentAction.onFocusData({
        propertyType: false,
        templateType: false,
        closingDateType: false,
        documentType: false,
        priceType: false,
        searchBuyerType: false,
        documentTypeColor: "",
        templateTypeColor: "",
        propertyTypeColor: "",
        closingDateTypeColor: "",
        priceTypeColor: "",
        searchBuyerTypeColor: "",
      })
    );
  };

  useEffect(() => {
    if(!getLicenseData){
      dispatch(accountHandle.getAllLicense({source,unmounted}))
      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      }
    }
  },[])

  useEffect(()=>{
    formOne.resetFields();
  }, [clearForm])

  useEffect(() => {
    if(getCategoryData?.length) {
      setFormFields((prev)=>({
        ...prev,
        contractId: getCategoryData[0]?.contractId,
        contractType: getCategoryData[0]?.contractType,
        name: getCategoryData?.[0]?.name,
      }))
    }
  }, [getCategoryData]);

  useEffect(() => {
    if(modalState?.isListingModal){
      showErtsModel()
    }else if(modalState?.isOfferModal){
      showOfferModal()
    }
  },[modalState])

  const OfferButtons = () => {
    return (
      <div style={{ display: "flex", gap: "10px", marginLeft: "10px" }}>
        <Button
          style={{
            background: "#3a74b6",
            color: "whitesmoke",
            fontWeight: "bold",
            borderRadius: "1px",
            border: "none",
            display: "flex",
            alignItems: "center",
          }}
          onClick={showOfferModal}
          size={screenWidth <= 400 ? "small" : "default"}
        >
          <PlusCircleOutlined style={{ fontSize: "17px", strokeWidth: "30", stroke: "whitesmoke" }} /> <div style={{ marginTop: "-1px" }}>Offer</div>
        </Button>
        <Button
          size={screenWidth <= 400 ? "small" : "default"}
          style={{
            background: "#3a74b6",
            color: "whitesmoke",
            fontWeight: "bold",
            borderRadius: "1px",
            border: "none",
            display: "flex",
            alignItems: "center",
          }}
          onClick={showErtsModel}
        >
          <PlusCircleOutlined style={{ fontSize: "17px", strokeWidth: "30", stroke: "whitesmoke" }} /> <div style={{ marginTop: "-1px" }}>Listing</div>
        </Button>
        {((!delegate && !isDelegateUsingMyAccount) || (isSuperAdmin && !delegate)) && (
          <Button
            size={screenWidth <= 400 ? "small" : "default"}
            style={{
              background: "#3a74b6",
              color: "whitesmoke",
              fontWeight: "bold",
              borderRadius: "1px",
              border: "none",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleRedirectToTC}
          >
            <div style={{ marginTop: "-1px" }}>TC Dashboard</div>
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.navContainer} >
      <SmallScreenFilters
          isModalVisible={toggled?.filter}
          setToggled={setToggled}
          toggled={toggled}
          dispatch={dispatch}
          filterSort={filterSort}
        />

    {/* Offer Modal  */}
    {isOfferModal && (<Modal width={"960px"} style={{ marginTop: "-100px" }} footer={false} title={<span style={{ display: "flex", justifyContent: "center", fontSize: "25px", color: "#178DFA", fontWeight:"bold" }}>MAKE AN OFFER</span>}
    visible={isOfferModal}
    onCancel={handleCloseModal}>
          <DocumentSection form={form} setProgress={setProgress} progress={progress} delegate={delegate}  isOfferModal={isOfferModal} formFields={formFields} setFormFields={setFormFields} />
    </Modal>)}

    {/* Erts Modal  */}
   {isListingModal && (
    <Modal width={"800px"} visible={isListingModal} title={<span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>{docsModal ? "UPLOAD LISTING" : "CREATE LISTING"}</span>} onCancel={handleCancelErts} footer={false} style={{ marginTop: "-50px" }}>
   <ErtsModel
      handleClear={handleClear}
      importModalStep={importModalStep}
      setisModalOpen={setisModalOpen}
      isModalOpen={isModalOpen}
      listingModalFields={listingModalFields}
      setListingModalFields={setListingModalFields}
      delegate={delegate}
      docsModal={docsModal}
      handleCancelErts={handleCancelErts}
      addPersons={addPersons} 
      addCorps={addCorps} 
      setAddPerson={setAddPerson} 
      setAddCorps={setAddCorps} 
    />
    </Modal>)}

      {
        thirdMax ? <div className={styles.centerNavSection}>
            <Button
              size={screenWidth <=400 ? "small":"default"}
              onClick={() => setToggled({...toggled, filter: !toggled?.filter})}
              style={{
                  border: "none",
                  background: "#3a74b6",
                  color: "whitesmoke",
                  fontWeight: "bold",
                  borderRadius: "2px",
              }}
            >
                Filter / Sort
            </Button>
            <div>
                {(limited || isDelegateHasLimitedAccess) ? null :  OfferButtons()}
            </div>
        </div> :
        <div className={styles.centerNavSection}>
          {/* search box  */}
          <div className={styles.searchInputBox}>
            <Form form={formOne} initialValues={{search:filterSort?.search}}>
              <Form.Item name="search" style={{ marginBottom: "0px" }}>
                <Input
                  allowClear
                  placeholder="search here..."
                  suffix={<SearchOutlined />}
                  style={{ maxWidth: "400px" }}
                  onChange={handleChange}
                />
              </Form.Item>
            </Form>
          </div>
          {/* filter box  */}
          <div
            className={styles.sortBox}
          >
            <Button
              style={{
                background: "#3a74b6",
                color: "whitesmoke",
                fontWeight: "bold",
                borderTopLeftRadius: "2px",
                borderBottomLeftRadius: "2px",
                border: "none",
                padding: "0px 10px"
              }}
            >
              <Popover
                content={<FilterMenu filterSort={filterSort}/>}
                trigger={["click", "hover"]}
                placement="bottom"
              >
                <FilterFilled
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                />
              </Popover>
            </Button>
            <div
              style={{
                height: "15px",
                border: "1.5px solid white",
                alignItems: "center",
                margin: "auto",
              }}
            ></div>
            <Button
              style={{
                background: "#3a74b6",
                color: "whitesmoke",
                fontWeight: "bold",
                borderTopRightRadius: "2px",
                borderBottomRightRadius: "2px",
                border: "none",
                padding: "0px 10px"
              }}
              onClick={handleClear}
            >
              <div style={{marginTop: "-1px"}}>Reset</div>
            </Button>
          </div>
          {/* sort box  */}
          {
            <div
              className={styles.sortBox}
            >
              <Button
                style={{
                  background: "#3a74b6",
                  color: "whitesmoke",
                  fontWeight: "bold",
                  borderTopLeftRadius: "2px",
                  borderBottomLeftRadius: "2px",
                  border: "none"
                }}
                onClick={()=>handleDateSortClick({type: "UPDATED",dispatch, filterSort})}
                size={screenWidth <=400 ? "small":"default"}
              >
                <UpDownArrows value={"Updated"} type="UPDATED" filterSort={filterSort}/>
              </Button>
              <div
                style={{
                  height: "15px",
                  border: "1.5px solid white",
                  alignItems: "center",
                  margin: "auto",
                }}
              ></div>
              <Button
                size={screenWidth <=400 ? "small":"default"}
                style={{
                  background: "#3a74b6",
                  color: "whitesmoke",
                  fontWeight: "bold",
                  borderTopRightRadius: "2px",
                  borderBottomRightRadius: "2px",
                  border: "none",
                }}
                onClick={()=>handleDateSortClick({type: "CLOSED",dispatch, filterSort})}
              >
                <UpDownArrows value={"Closing"} type="CLOSED" filterSort={filterSort}/>
              </Button>
            </div>
          }
          {/* Create Offer Buttons */}
          {(limited || isDelegateHasLimitedAccess) ? null :  OfferButtons()}
        </div>
      }
    </div>
  );
};

export default PropertyNavbar;
