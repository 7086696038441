import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, List, Typography, Checkbox, Select, Divider, Space, Input } from "antd";
import { clientAction } from "../state/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { capitalizeStr, formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PhoneOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import confirm from "antd/lib/modal/confirm";
import { v4 as uuidv4 } from "uuid";
function PhoneDetails({ data, selectedClient, setModelDrawer, modelDrawer, section, source }) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [isFormOpen, setIsFormOpen] = useState(data.length === 0);
  const [editingItem, setEditingItem] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [customOptions, setCustomOptions] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [tempCustomRelation, setTempCustomRelation] = useState("");

  const [customError, setCustomError] = useState("");
  const predefinedOptions = [
    { id: uuidv4(), label: "Home", value: "HOME" },
    { id: uuidv4(), label: "Personal", value: "PERSONAL" },
    { id: uuidv4(), label: "Work", value: "WORK" },
    { id: uuidv4(), label: "Mobile", value: "MOBILE" },
  ];
  useEffect(() => {
    let newCustomOptions = [...predefinedOptions]; // ✅ Start with predefined options

    for (let phone of data) {
      let matchingOption = newCustomOptions.find((phoneType) => phoneType?.value?.toLowerCase() === phone?.type?.toLowerCase());

      if (!matchingOption) {
        newCustomOptions.push({
          id: uuidv4(),
          label: phone?.type,
          value: phone?.type.toUpperCase(),
        });
      }
    }

    setCustomOptions(newCustomOptions);
  }, [data]);
  const addItem = () => {
    const trimmedValue = tempCustomRelation.trim().toLowerCase();

    if (!trimmedValue) {
      return;
    }

    // Check if the value already exists in customOptions
    const isDuplicate = customOptions.some((item) => item.value.trim().toLowerCase() === trimmedValue);

    if (isDuplicate) {
      setCustomError("This type already exists!");
      return;
    }

    // Add new custom type if it's not duplicate
    const newOption = { id: uuidv4(), label: tempCustomRelation, value: tempCustomRelation };
    setCustomOptions([...customOptions, newOption]);
    setSelectedRelation(tempCustomRelation);
    setCustomError(""); // Clear error after successful add
    setTempCustomRelation(""); // Clear input
  };
  const showDeleteConfirm = (emailToDelete) => {
    confirm({
      title: "Are you sure you want to Remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(emailToDelete);
      },
    });
  };
  const handlephoneDataSubmit = (values) => {
    const isPrimary = values.isPrimary || editingItem?.isDefault || false; // Ensure it's always true/false

    let updatedMobilePhoneNumber = data?.map((item) => {
      if (item === editingItem) {
        return {
          ...item,
          countryCode: "+1",
          phoneNumber: values?.phoneNumber,
          type: values?.type.toUpperCase(),
          isDefault: editingItem.isDefault ? true : isPrimary, // Keep default if already true
        };
      }
      return isPrimary ? { ...item, isDefault: false } : item; // Remove previous default if new one is primary
    });

    if (!editingItem) {
      updatedMobilePhoneNumber = [
        ...updatedMobilePhoneNumber.map((item) => ({
          ...item,
          isDefault: isPrimary ? false : item.isDefault, // Remove previous default if new is primary
        })),
        {
          countryCode: "+1",
          phoneNumber: values?.phoneNumber,
          type: values?.type.toUpperCase(),
          isDefault: isPrimary,
        },
      ];
    }

    // Prepare final object
    let updatedPhoneData = {
      phones: updatedMobilePhoneNumber,
    };

    if (isPrimary) {
      updatedPhoneData = {
        ...updatedPhoneData,
        phone: values?.phoneNumber,
      };
    }

    dispatch(clientAction.editPhone(updatedPhoneData, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
    form.resetFields();
    setIsFormOpen(false);
    setEditingItem(null);
    dispatch(clientAction.resetAddNewRequest());
  };
  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);

  const init1 = editingItem?.phoneNumber
    ? // && data?.mobilePhoneNumber?.phoneNumber?.length === 10i
      editingItem?.phoneNumber
    : "";

  const handleEdit = (item) => {
    dispatch(clientAction.resetAddNewRequest());
    setIsFormOpen(!isFormOpen);
    setEditingItem(item);

    form.setFieldsValue({
      phoneNumber: item?.phoneNumber || "",
      type: item?.type.toUpperCase() || undefined,
      isPrimary: item?.isPrimary || false,
    });
  };

  const handleDelete = (phoneToDelete) => {
    let updatedPhoneNumbers = data?.filter((item) => item._id !== phoneToDelete._id); // Remove the deleted phone number

    let newPrimaryPhone = null;

    if (phoneToDelete.isDefault && updatedPhoneNumbers.length > 0) {
      // If the deleted phone number was default, assign the next available one as default
      updatedPhoneNumbers[0] = { ...updatedPhoneNumbers[0], isDefault: true };
      newPrimaryPhone = updatedPhoneNumbers[0]; // Capture the new default phone number
    }

    // Prepare the updated data payload
    let updatedData = { phones: updatedPhoneNumbers };

    if (newPrimaryPhone) {
      updatedData = {
        ...updatedData,
        phone: newPrimaryPhone.phoneNumber, // Update the default phone number
      };
    }

    dispatch(clientAction.deletePhone(updatedData, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
  };
  const renderForm = () => {
    return (
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 15 }}
        //initialValues={
        initialValues={{ phoneNumber: editingItem && !isAddNew ? init1 : "", type: editingItem && !isAddNew ? editingItem?.type : "", isPrimary: data.length === 0 ? true : editingItem && !isAddNew ? editingItem?.isDefault : data?.isPrimary }}
        // type: data?.type,
        // isPrimary: data.length===0?true:data?.isPrimary,
        // }
        onFinish={handlephoneDataSubmit}
        onValuesChange={() => {
          const values = form.getFieldsValue();
          setIsSaveDisabled(!values.phoneNumber || !values.type); // Disable if either field is empty
        }}
        autoComplete="off"
      >
        <Form.Item label={"Phone Number"} name={"phoneNumber"} hasFeedback rules={[{ required: true, message: "" }, { whitespace: true }]}>
          <PhoneInput ref={inputRef} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder={"Enter Phone No."} />
        </Form.Item>
        <Form.Item name="type" label="Phone Type">
          <Select
            style={{ width: 300 }}
            placeholder="Select Phone type"
            value={selectedRelation}
            onChange={(value) => setSelectedRelation(value)}
            dropdownRender={(menu) => (
              <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <div>
                    <Input
                      placeholder="Enter custom type"
                      ref={inputRef}
                      value={tempCustomRelation}
                      onChange={(e) => {
                        setTempCustomRelation(e.target.value);
                        setCustomError("");
                      }}
                      onPressEnter={addItem}
                    />
                    {customError && <Typography.Text type="danger">{customError}</Typography.Text>}
                  </div>
                  <Button type="text" onClick={addItem}>
                    Add
                  </Button>
                </Space>
              </div>
            )}
            options={customOptions.map((item) => ({ label: capitalizeStr(item?.label), value: item?.value }))}
          />
        </Form.Item>
        {!editingItem?.isDefault && (
          <Form.Item name="isPrimary" valuePropName="checked" disabled={data.length === 0} wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox disabled={data.length === 0 || editingItem?.isDefault}>Make this as primary Phone Number</Checkbox>
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ span: 24 }}>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button
              onClick={() => {
                setIsFormOpen(false);
                setEditingItem(null);
                form.resetFields()
                dispatch(clientAction.resetAddNewRequest());
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={isSaveDisabled}>
              Save
            </Button>
            
          </div>
        </Form.Item>
      </Form>
    );
  };

  const groupedAndSortedPhones = () => {
    if (!data) return {};

    // 1️⃣ Sort: Default emails should come first
    const sortedData = [...data].sort((a, b) => (b.isDefault ? 1 : -1));

    // 2️⃣ Dynamically create groups based on unique email types
    let grouped = {};
    let defaultSection = null; // Track which section has the default email

    sortedData.forEach((item) => {
      const type = item.type; // No need for "Other" fallback

      if (!grouped[type]) {
        grouped[type] = []; // Initialize group if not exists
      }

      grouped[type].push(item);

      if (item.isDefault) {
        defaultSection = type; // Store the section where default exists
      }
    });

    // 3️⃣ If there's a default section, move it to the top
    if (defaultSection) {
      const reorderedGroups = { [defaultSection]: grouped[defaultSection] };

      // Add remaining sections in order, skipping the default one since it's already added
      Object.keys(grouped).forEach((key) => {
        if (key !== defaultSection) {
          reorderedGroups[key] = grouped[key];
        }
      });

      return reorderedGroups;
    }

    return grouped;
  };

  const phonesGroup = groupedAndSortedPhones();
  useEffect(() => {
    form?.resetFields();
    setEditingItem(null);
  }, [isAddNew]);
  useEffect(() => {
    const values = form.getFieldsValue();
    setIsSaveDisabled(!values.phoneNumber || !values.type); // Disable if either is empty
  }, [form, editingItem]);
  useEffect(() => {
    if (data.length === 0) {
      setIsFormOpen(true);
      dispatch(clientAction.addNewRequest());
    }
  }, [data]);
  return (
    <>
      {!(isAddNew || isFormOpen) && (
        <div style={{ maxHeight: "200px", overflowY: "auto", width: "100%", padding: "5px", marginTop: "-20px" }}>
          {Object.keys(phonesGroup).map((type) => (
            <div key={type} style={{ marginTop: "10px" }}>
              <Typography.Title level={4} style={{ marginBottom: "-5px" }}>
                {capitalizeStr(type)} Phone
              </Typography.Title>
              <List
                dataSource={phonesGroup[type]}
                renderItem={(item) => (
                  <List.Item style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography.Text>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <PhoneOutlined style={{ marginRight: "10px" }} /> {formatPhoneNumber(item?.phoneNumber)}
                        </div>
                        <div>{item?.isDefault && <span style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>(Default)</span>}</div>
                      </div>
                    </Typography.Text>

                    <div style={{ display: "flex" }}>
                      <EditOutlined onClick={() => handleEdit(item)} />
                      {data?.length > 1 && <DeleteOutlined style={{ marginLeft: "10px" }} onClick={() => showDeleteConfirm(item)} />}
                    </div>
                  </List.Item>
                )}
              />
            </div>
          ))}
        </div>
      )}
      {!isAddNew && editingItem && (
        <>
          <Typography.Title level={4} style={{ marginBottom: "0px" }}>
            {capitalizeStr(editingItem?.type)} Phone
          </Typography.Title>
          <Typography.Text style={{ fontSize: "17px" }}>
            <PhoneOutlined style={{ marginRight: "10px" }} />
            {formatPhoneNumber(editingItem?.phoneNumber)}
            <span style={{ fontSize: "14px", color: "grey" }}>{editingItem?.isDefault ? " (Default)" : ""}</span>
          </Typography.Text>
        </>
      )}
      {(isFormOpen || isAddNew) && (
        <div style={{ position: "sticky", bottom: 0, background: "white", zIndex: 10, padding: "10px 0" }}>
          <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", color: "grey", marginBottom: "20px" }}>
            {isAddNew || data.length === 0 ? "Add" : "Edit"} {source === "officePhoneNumber" ? "Office Number" : "Mobile Number"}
          </span>
          {renderForm()}
        </div>
      )}
    </>
  );
}
export { PhoneDetails };
