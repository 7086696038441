import { Tooltip, Typography } from "antd";
import React from "react";
import { FaMedal } from "react-icons/fa";
import styles from "../styles/Client.module.css";
import { TitleDetails } from "./TitleDetails";
import { capitalizeStr } from "../../Common/utils/extraFunctions";

const Title = ({ setModelDrawer, selectedClient, personDetails }) => {
  const designations = personDetails?.designations?.length > 0
  ? personDetails.designations
  : personDetails?.designation?[{
      name: personDetails?.designation || "",
      isDefault: true,
    }]:[];
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Title"} placement="left" >
          <FaMedal className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              <TitleDetails
                setModelDrawer={setModelDrawer}
                data={designations}
                selectedClient={selectedClient}
              />
            ),
            title: "Title Details",
            toolTip: "Add Title"
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-job.svg" alt=""></img>
        </div>
        <div style={{ marginLeft: "-10%" }} className={styles.userfeild}>
          {" "}
          {personDetails.designation
            ?capitalizeStr(personDetails.designation) 
            
            : "Enter Title"}{" "}
        </div>
      </div>
    </Text>
  );
};

export { Title };
