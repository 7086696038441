/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Spin, Tabs, Tooltip, } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  PlusCircleFilled,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ImFolderDownload } from 'react-icons/im'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MailOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CreateDocs from "./CreateDocs";
import { SignableAndReadOnly } from "./SignableAndReadOnly";
import { PropertyHandlers } from "../state/actions";
import { DraggerComp } from "../../Common/components/DraggerComp";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { checkIfDisabled, createOfferContractTypes, customerDocsContTractTypes, disabledTransactionText, listingContractTypes } from "../../Common/commondata/commonData";
import PropertyDocsTable from "./PropertyDocsTable";
import { changeExtensiontoLowerCase } from "../../Common/utils/extraFunctions";
import { VscNewFolder } from 'react-icons/vsc';
import { RiFolderSharedFill } from "react-icons/ri";
import CreateFolderModal from "./CreateFolderModal";
import MoveToFolder from "./MoveToFolder";
import { getBuildDataForCustomerdocs } from "../utils/helper";


const PropertyDocsModal = ({ isModalOpen, setIsModalOpen, role, section, item, isWeb }) => {
  const delegate = (section === "DELEGATE");
  let { propertyDocs, propertDocsLoading, propertDocsError, updatedPropertyDoc, uploadedPdfDocSuccess, moveToFolderData, moveToFolderDataLoading, zipDownloadLoading } = useSelector((state) => state.PropertyReducer,shallowEqual);
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });
  const location = useLocation();
  const dispatch = useDispatch();
  const uploadFile = useRef("");
  const agentDetails = (item?.currentAgentId);
  const {filterSort} = useSelector((state) => state.PropertyReducer);
  const [currentPropertyDocId, setCurrentPropertyDocId] = useState({
    clickedItem: "",
    source: "",
  });

  const [loading, setLoading] = useState(false);
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isUploadDocsModal: false,
    isEmailModalVisible: false,
    isLegendOpen: false,
    sendMultipleDoc:false,
    isclearTransactionLaneData:false,
  });
  const [page, setPage] = useState([1]);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState({
    createFolder: false
  });
  const [moveFolderModalOpen, setMoveFolderModalOpen] = useState(false);
  const [folderName, setFolderName] = useState();
  const [propertyDocsWithType, setPropertyDocsWithType] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    offerDocs:[],
    propertyDocs:[],
});

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();


  useEffect(()=>{
    const params = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.isProperty  ? { propertyId: item?.propertyId } : {}),
      openFor: item?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
      ...(delegate ? {delegateSide: (item.isProperty)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
      section: "PROPERTY_DOCS",
      ...(isWeb ? {isWeb: true} : {}),
    };
    if (item?.offerId || item?.propertyId) {
      dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source }));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [updatedPropertyDoc, uploadedPdfDocSuccess])

  useEffect(() => {
    const delegateSide = section === "DELEGATE" && role;
    if (item?.offerId || item?.propertyId) {
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId: item?.offerId, propertyId: item?.propertyId, setLoading, unmounted, source, delegateSide, delegateOwnerId: delegateSide && item?.currentAgentId?._id }));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, []);
  // Property Docs functions
  const handleActivePropertyDocsOk = () => {
    setIsModalOpen({ ...isModalOpen, propertyDocs: false });
    dispatch(PropertyHandlers.resetFolderOpenState());
  };

  const handleActivePropertyDocsCancel = () => {
    setIsModalOpen({ ...isModalOpen, propertyDocs: false });
    dispatch(PropertyHandlers.resetFolderOpenState());
   };

  const handleuploadmodal = () => {
    setNestedModalOpen({ ...nestedModalOpen, isUploadDocsModal: true });
    const type="SendEmail"
    const params = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.isProperty  ? item?.propertyId ? { propertyId: item?.propertyId } : {} : {}),
      openFor: item?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
      personId: agentDetails?._id,
      ...(delegate ? {delegateSide: (item.isProperty)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
    };
    dispatch(PropertyHandlers.getPropertyDocsFun({params, unmounted, source,type}));
   return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  };

  const handleCreateAmendExtend = ({ parentDoc, docSecondItem }) => {
    try {
      const officeDetails = {
        officeLogo: agentDetails?.brokerageData?.logo || "",
        name: agentDetails?.brokerageData?.name?.trim() || "",
        address: agentDetails?.brokerageData?.address || "",
        agentId: agentDetails?._id,
      };
      const buildData = getBuildDataForCustomerdocs({clientDocId: parentDoc?._id,  contractType: docSecondItem?.contractType, contractId: docSecondItem?.contractId, currentAgentDetails: agentDetails, transactionData: parentDoc.transactionData, delegate, item, officeDetails, docSecondItem });
      // console.log("buildData", buildData);
      dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname }));
    } catch (error) {
      
    }
  };


  // ---------------  functions to open the files ---------------------
  const handlePropertyDocs = (eachDoc = {}) => {
    if (typeof eachDoc !== "object") return;
    if (eachDoc.isPdf) {
      window.open(eachDoc.pdfLink, "_blank");
    } else if (eachDoc.fileType === "IMAGE" || eachDoc.fileType === "PDF") {
      if (eachDoc?.fileUrl) {
        window.open(eachDoc?.fileUrl);
      }
    } else {
      const isCustomerDoc = customerDocsContTractTypes.includes(eachDoc.contractType);
      let buildData = {
        documentId: eachDoc.documentId,
        contractType: eachDoc.contractType,
        contractId: eachDoc.contractId,
        openFor: isCustomerDoc ? (eachDoc.RTDInitiator === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT") : item?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
        token: localStorage.getItem("userAuth"),
        builtForId: agentDetails?._id,
        key: agentDetails?._id,
        ...(item?.offerId && !isCustomerDoc ? { offerId: item.offerId } : {}),
        ...(delegate ? { delegateOwnerId: agentDetails?._id } : {}),
        builtForSection: "DOCUMENT",
        signable: true,
        ...(eachDoc.RTDInitiator && { RTDInitiator: eachDoc.RTDInitiator }),
        ...(item?.propertyId && !isCustomerDoc ? { propertyId: item?.propertyId } : {}),
      };
      dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname }));
    }
  };

  const handleOPenDocs = (ele) => {
    if (ele?.fileUrl) {
      window.open(ele?.fileUrl);
    }
  };

  const handleShareUnshareFileConfirm = (ele, checkBoxValue ) => {
    confirm({
      title: checkBoxValue
      ? ` Are you sure you want to show this file in the Shared folder?`
      : `Are you sure you want to remove this file from the the Shared folder?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined  style={{  color: "orange" }}/>,
      onOk() {
        handleShared(ele);
      },
      onCancel() {},
    });
  };

// The common function used in handleconfirm & handleconfirm2
  const handleShared = (ele) => {
    const params = {
      ...(item?._id ? { propertyDocId: ele._id } : {}),
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.propertyId ? { propertyId: item?.propertyId } : {}),
      ...(ele.isShared ? { unShare: "true" } : {}),
      ...(delegate ? {delegateSide: (item.isProperty)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
    };

    if (item?.offerId) {
      dispatch(PropertyHandlers.sharePropertyDocs(params, true));
    } else {
      dispatch(PropertyHandlers.sharePropertyDocs(params, true));
    }
  };

//   Function to Upload doc will be passed as prop
  const uploadTemp = (setShowInputFileName,fileNametext,setFileNametext) => {
    setShowInputFileName(false);
    uploadFile.current.append("filename", changeExtensiontoLowerCase(fileNametext));
    if (item?.offerId) uploadFile.current.append("offerId", item?.offerId);
    uploadFile.current.append("personId",agentDetails?._id);
    if(delegate){
      if (item.isProperty) {
        uploadFile.current.append("delegateSide", "SELLER")
        uploadFile.current.append("delegateOwnerId", agentDetails?._id)
      } else {
        uploadFile.current.append("delegateSide", "BUYER");
        uploadFile.current.append("delegateOwnerId", agentDetails?._id)
      }
    }
    if (item?.isProperty) uploadFile.current.append("propertyId", item?._id);
    uploadFile.current.append("openFor", (item?.isProperty) ? "SELLER_AGENT":"BUYER_AGENT");
    dispatch(PropertyHandlers.uploadPropertyDocsFun(uploadFile.current));
    setFileNametext("");
    setNestedModalOpen({...nestedModalOpen,isUploadDocsModal:false});
  };

  const checkForDisable = checkIfDisabled({openFor: (item?.isProperty) ? "SELLER" : "BUYER", actualPropertyStatus: item?.actualPropertyStatus, buyingStatus: item?.actualOfferStatus, offerStatus: item?.status});
  const handleCreateDocCancel = () => setNestedModalOpen({ ...nestedModalOpen, isUploadDocsModal: false })

  // Function To Delete Document from propertyDocs folder

  const handleDeletePropertyDocs = (ele, docType) => {
    // Determine the message and content based on requiresWholeReRender
    const isWholeReRenderRequired = listingContractTypes.includes(ele.contractType) || createOfferContractTypes.includes(ele.contractType);
    
    const confirmationMessage = isWholeReRenderRequired
      ? (
        <>
          Are you sure you want to delete this <span style={{ color: 'red' }}>{ele?.contractType}</span> document? This action will affect all other documents
        </>
      )
      : "Are you sure you want to delete this document?";
    
    const confirmationContent = isWholeReRenderRequired 
        ? "Note: This action is irreversible. Deleting this document will permanently remove it from your account and all associated documents." 
        : "Note: This action is irreversible. Deleting this document will permanently remove it from your account.";

    confirm({
      title: confirmationMessage,
      content: confirmationContent,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined style={{  color: "orange" }} />,
      onOk() {
     if (ele.isCustomerDoc) {
        dispatch(PropertyHandlers.deleteListing({ docId: ele._id, source:"PROPERTY_CLIENT_DOCS" }));
    } else if (docType === 'uploadedDoc') {
          dispatch(PropertyHandlers.removePropertyDocsFun(ele?._id));
        } else if (docType === 'contractDoc') {
          const payloadData = {
            documentId: ele?.documentId,
            ...(delegate ? {delegateSide: (item.isProperty)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
            ...(item?.isProperty && { propertyId: item?.propertyId }),
            ...(item?.isOffer && { offerId: item?.offerId }),
            section : "PROPERTY_DOCS",
            requiresRender: true,
            requiresWholeReRender: isWholeReRenderRequired,
            filterSort
          };
          dispatch(PropertyHandlers.deletePropertyContractDocs({ payloadData }));
        }
      },
      onCancel() {},
    });
};

  const handleToggleDashboard = (ele, checkBoxValue) => {
   
    const payloadData = {
      documentId: ele?.documentId,
      isToggleFrom: "PROPERTY_DOCS",
      ...(delegate ? {delegateSide: (item.isProperty)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
      ...(item?.isProperty && { propertyId: item?.propertyId }),
      ...(item?.isOffer && { offerId: item?.offerId }),
      requiresRender: true,
      showInTransactionDashboard: checkBoxValue
    };
    confirm({
      title: checkBoxValue
        ? `Are you sure you want to move this document to the transaction dashboard?`
        : `Are you sure you want to remove this document from the transaction dashboard?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined  style={{  color: "orange" }}/>,
      onOk() {
        dispatch(PropertyHandlers.toggleDocumentInTransactionDashboard({ payloadData }));
      },
      onCancel() { },
    });
  };

  const handleOpenMoveToFolder = () => {

    setMoveFolderModalOpen(true)
    const params = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.propertyId ? { propertyId: item?.propertyId } : {}),
      openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
      ...(delegate ? { delegateSide: (item?.isProperty || item?.isErts) ? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id } : {}),
      section: "PROPERTY_DOCS",
      // personId: agentDetails?._id,
    };
    dispatch(PropertyHandlers.getMoveToFolderFun({ params }))
  }

  const downloadAllFilesAsZip = () => {
    let documentDetails = [];
    const processDocument = (doc, parentPath = "") => {
      if (!doc || typeof doc !== "object") return; // Handle null or invalid docs
    
      const docName = doc.documentName || doc.fileName || doc.name || "Unnamed Document";
    
      // Compute full parent path for nesting
      const currentPath = parentPath ? `${parentPath} > ${docName}` : docName;
    
      try {
        // Handle IMAGE / PDF files
        if (["IMAGE", "PDF"].includes(doc?.fileType) && doc?.storageType === "FILE") {
          documentDetails.push({
            type: doc.fileType,
            link: doc.fileUrl || "",
            fileName: docName,
            parentFolder: parentPath || null,
            ...(["PDF"].includes(doc?.fileType) && doc?.contractType ? {contractType: doc?.contractType} : {}),
          });
          return;
        }
    
        // Handle customer PDF documents and also for uploaded Specific PDFs though create new
        if (((doc.isCustomerDoc && doc.isPdf) || doc.isPdf) && doc.storageType === "FILE") {
          documentDetails.push({
            type: "PDF",
            link: doc.pdfLink || "",
            fileName: docName,
            parentFolder: parentPath || null,
            ...(doc?.contractType ? {contractType: doc?.contractType} : {}),

          });
          return;
        }
    
        // Handle general DOCUMENT files
        if (!doc.isPdf && (doc.documentId || doc._id) && doc.storageType === "FILE") {
          documentDetails.push({
            type: "DOCUMENT",
            documentId: doc?.documentId || doc?._id,
            documentName: docName,
            contractId: doc?.contractId,
            contractType: doc?.contractType || '',
            parentFolder: parentPath || null,
            RTDInitiator: doc?.RTDInitiator,
          });

          // Handle secondary linked documents (matchedDocuments)
          if (Array.isArray(doc.matchedDocuments) && doc.matchedDocuments.length > 0) {
            doc.matchedDocuments.forEach((matchedDocumentItem) => {
              if (!matchedDocumentItem) return;
              const matchedDocName = matchedDocumentItem.documentName || matchedDocumentItem.fileName || matchedDocumentItem.name || '';
              documentDetails.push({
                type: "DOCUMENT",
                documentId: matchedDocumentItem.documentId || matchedDocumentItem._id,
                contractType: matchedDocumentItem.contractType || '',
                contractId: matchedDocumentItem?.contractId,
                documentName: matchedDocName,
                parentFolder: parentPath || null,
                RTDInitiator: doc?.RTDInitiator,
              });
            });
          }
          return;
        }
    
        // Handle folders and nested items
        if (doc.storageType === "FOLDER" && Array.isArray(doc.nestedItems) && doc.nestedItems.length > 0) {
          doc.nestedItems.forEach((nestedItem) => processDocument(nestedItem, currentPath));
        }
      } catch (error) {
        console.error("Error processing document:", doc, "Error:", error);
      }
    };
    
    // Process all propertyDocs
    if (Array.isArray(propertyDocs) && propertyDocs.length > 0) {
      propertyDocs.forEach((doc) => processDocument(doc));
    }    

    const payload = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.propertyId ? { propertyId: item?.propertyId } : {}),
      documentDetails,
      openFor: item?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
      section: "PROPERTY_DOCS",
    };
    dispatch(PropertyHandlers.downloadAllFilesAsZip({ payload }));
  }

  return (
    <>
      {createFolderModalOpen?.createFolder && <CreateFolderModal setCreateFolderModalOpen={setCreateFolderModalOpen} createFolderModalOpen={createFolderModalOpen} getCoordinateItem={item} delegate={delegate} agentDetails={agentDetails} setFolderName={setFolderName} subFolder={false}/>}

      {/* Move To Folder Modal */}
      {moveFolderModalOpen && <Modal
        title={<span style={{ display: "flex", justifyContent: "center", color: "#178DFA", fontWeight: "bold", fontSize: "22px" }}>Move to Folder</span>}
        open={moveFolderModalOpen}
        onCancel={() => setMoveFolderModalOpen(false)}
        footer={null}
      >
        <MoveToFolder setMoveFolderModalOpen={setMoveFolderModalOpen} moveToFolderData={moveToFolderData} moveToFolderDataLoading={moveToFolderDataLoading} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} getCoordinateItem={item} />
      </Modal>}
      
      {/* Main property docs modal  */}
      <Modal
        style={{ top: "20px", height: "500px" }}
        width={750}
        title={
          <div
            className="documentModal"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            <div style={{ width: "10%", textAlign: "left", cursor: "pointer" }}>
              <Tooltip title="Create New Folder" placement="top">
                <VscNewFolder
                  style={{
                    fontSize: isSmallScreen ? "20px" : "25px",
                    color: "rgb(8,81,145)",
                  }}
                  onClick={() => setCreateFolderModalOpen({...createFolderModalOpen,createFolder:true})}
                />
              </Tooltip>
            </div>
            <div style={{ width: "10%", cursor: "pointer" }}>
              <Tooltip title="Move to Folder" placement="top">
                <RiFolderSharedFill
                  style={{
                    fontSize: isSmallScreen ? "20px" : "27px",
                    color: "rgb(8,81,145)",
                  }}
                  onClick={handleOpenMoveToFolder}
                />
              </Tooltip>
            </div>
            <div style={{ width: "90%", textAlign: "center", fontSize: isSmallScreen ? "15px" : "20px", color: "#178DFA" }}>
              Property Docs
            </div>
            <div
              style={{
                width: "24%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: isSmallScreen ? "10px" : "20px",
                marginRight: "10px",
              }}
            >
              <div>
                {propertDocsLoading || zipDownloadLoading ? <div><Spin size="small"></Spin></div> : !propertDocsLoading && !propertDocsError && propertyDocs?.length > 0 ?
                  <Tooltip title="Download All files as Zip" placement="top">
                    <ImFolderDownload style={{ opacity: 1, color: '#1890ff', cursor: "pointer" }} size={isSmallScreen ? 16 : 25} onClick={downloadAllFilesAsZip} />
                  </Tooltip> : <></>
                }
              </div>
              <Button
                onClick={() =>
                  setNestedModalOpen({
                    ...nestedModalOpen,
                    sendMultipleDoc: true,
                    isEmailModalVisible: true,
                  })
                }
                icon={
                  <MailOutlined
                    style={{ fontSize: isSmallScreen ? "16px" : "25px" }}
                  />
                }
                style={{
                  color: "#085191",
                  border: "none",
                }}
              />
              <Button
                className="borderless-button"
                style={{ border: 0 }}
                icon={
                  <PlusCircleFilled
                    style={{
                      fontSize: isSmallScreen ? "16px" : "25px",
                      color: "#085191",
                    }}
                    {...(checkForDisable?.disabled
                      ? { onClick: disabledTransactionText }
                      : { onClick: () => handleuploadmodal() })}
                  />
                }
              />
            </div>
          </div>
        }
        footer={false}
        open={isModalOpen?.propertyDocs}
        onOk={handleActivePropertyDocsOk}
        onCancel={handleActivePropertyDocsCancel}
        className="documentModal"
      >
        <div style={{ height: "500px", marginTop:"-20px" }} className="documentModal">
          {propertDocsLoading ? (
            <div style={{ width: "100%", fontSize: "15px", height: "40%", paddingTop: "10%", textAlign: "center" }}>
              <Spin />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                color: "black",
                height: "100%" ,
                overflowY: "auto",
                overflowX: "hidden",
                paddingRight: "5px",
              }}
            >
              <PropertyDocsTable
                propertyDocs= {propertyDocs}
                propertyDocsWithType= {propertyDocsWithType}
                setPropertyDocsWithType= {setPropertyDocsWithType}
                role={role}
                item={item}
                handleOPenDocs={handleOPenDocs}
                handlePropertyDocs={handlePropertyDocs}
                handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
                handleToggleDashboard={handleToggleDashboard}
                handleDeletePropertyDocs={handleDeletePropertyDocs}
                isSmallScreen={isSmallScreen}
                folderName= {folderName}
                setFolderName= {setFolderName}
                delegate={delegate}
                setCreateFolderModalOpen={setCreateFolderModalOpen}
                createFolderModalOpen={createFolderModalOpen}
                agentDetails={agentDetails}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                handleCreateAmendExtend={handleCreateAmendExtend}
              />
            </div>
          )}
        </div>
      </Modal>

      {/* upload docs modal here  */}
      {
        nestedModalOpen?.isUploadDocsModal && <Modal
          width={800}
          open={nestedModalOpen?.isUploadDocsModal}
          footer={false}
          onCancel={handleCreateDocCancel}
          style={{marginTop: "-30px"}}
        >
          <div
            style={{
              textAlign: "center",
              color: "#888888",
              height: "72vh",
              fontSize: "18px",
              padding: "5px",
              marginTop: "10px",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              // borderTopLeftRadius: "5px",
              // borderTopRightRadius: "5px",
              // borderBottomRightRadius: "5px",
              // borderBottomLeftRadius: "5px",
              // overflowY: "auto"
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              style={{
                position: "absolute",
                height: "5px",
                fontSize: "15px",
                cursor: "pointer",
                top: "0px",
                left: "0px",
                zIndex: "1",
                border: "none",
              }}
              onClick={() => {
                // Handle the back button click event here
                setNestedModalOpen({
                  ...nestedModalOpen,
                  isUploadDocsModal: false,
                });
              }}
            >
              <text style={{ color: "gray", marginLeft: "5px" }}>Back</text>
            </Button>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", width: "100%", fontSize: "15px" }}>
                <Tabs
                  defaultActiveKey="1"
                  style={{ width: "100%", height: "calc(85vh - 8rem)" }}
                  className="centered-tabs"
                >
                  <TabPane
                    tab="Create New"
                    key={"1"}
                    style={{ width: "100%", height: "100%" }}
                    // disabled={role === "BUYER" ? true : false}
                  >
                    <CreateDocs role={role} item={item} section={section} handleCreateDocCancel={handleCreateDocCancel} loading={loading}/>
                  </TabPane>
                  <TabPane
                    tab="Upload"
                    key={"2"}
                    style={{ width: "100%", height: "100%" }}
                  >
                  <div style={{width:"100%",margin:"auto",marginTop:"20px",display:"flex",justifyContent:"center"}}>
                    <DraggerComp
                    uploadFile={uploadFile}
                    isModalOpen={isModalOpen}
                    setNestedModalOpen={setNestedModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    uploadTemp={uploadTemp}
                    accept=".pdf,.PDF,image/*"
                    />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Modal>
      }


      <SignableAndReadOnly
        role={role}
        section={section}
        source={"PROPERTY_DOCS"}
        docSelected={true}
        setPropertyDocsModalOpen={setIsModalOpen}
        nestedModalOpen={nestedModalOpen}
        setNestedModalOpen={setNestedModalOpen}
        currentPropertyDocId={currentPropertyDocId}
        setCurrentPropertyDocId={setCurrentPropertyDocId}
        selectedData={item}
        delegateOwnerId={delegate &&  agentDetails?._id}
        documentId={currentPropertyDocId.documentId}
       delegate={ delegate ? ((item?.isProperty) ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {} }
      page={page}
      setPage={setPage}
      />
    </>
  );
};

export { PropertyDocsModal };

